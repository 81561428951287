import styled from 'styled-components';

export const CheckBoxComponent = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 8px;
  transform: translateY(-25%);
  cursor: pointer;
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  margin-bottom: 1.25rem;
`;
