import React, { Component } from 'react';
import withIntl from '../../i18n/withIntl';

import { Helmet, Header, Footer } from '../';

import { GlobalStyle } from '../../theme/theme';
import { MainLayout, Main } from './layout-styled-component';

interface LayoutProps {
  children;
  pageContext;
  disableLink;
}

class Layout extends Component<LayoutProps> {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <MainLayout id="main">
        <Helmet pageContext={this.props.pageContext} pageProps={this.props} />
        <GlobalStyle />
        <Header disableLink={this.props.disableLink} />
        <Main>{this.props.children}</Main>
        <Footer pageProps={this.props} />
      </MainLayout>
    );
  }
}

export default withIntl(Layout);
