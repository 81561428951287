import { Link } from 'gatsby';
import styled, { theme } from '../../theme/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 20px 0px;
  &.bottom {
    align-items: flex-end;
  }
  @media (max-width: ${theme.sizes.tablet}px) {
    flex-direction: column;
    align-items: start;
    &.bottom {
      align-items: start;
    }
  }
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  @media (min-width: ${theme.sizes.tablet}px) {
    align-items: left;
  }
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const LogoImg = styled.img`
  width: 100px;
  margin-bottom: 20px;
  margin-left: -17px;
`;

export const Slogan = styled.p`
  font-size: 1em;
  color: white;
  text-align: left;
  width: 200px;
  font-weight: 300;
`;

export const FooterLinks = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media (max-width: ${theme.sizes.tablet}px) {
    flex-direction: column;
  }
`;

export const FooterLinkInternal = styled(Link)`
  font-size: 1em;
  color: ${theme.regolarWhite};
  padding: 20px 0 0 0;
  text-decoration: none;
  font-weight: 300;
  &:hover {
    text-decoration: underline;
  }
`;

export const FooterLinkExternal = styled.a`
  font-size: 1em;
  color: ${theme.regolarWhite};
  padding: 20px 0 0 0;
  text-decoration: none;
  font-weight: 300;
  &:hover {
    text-decoration: underline;
  }
`;

export const FooterLinkSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 0px 0px 60px;
  @media (max-width: ${theme.sizes.tablet}px) {
    padding: 0px 60px 0px 0px;
  }
`;

export const FooterLinkTitle = styled.h2`
  color: ${theme.regolarWhite};
  font-weight: 400;
  @media (max-width: ${theme.sizes.tablet}px) {
    margin: 40px 0 0 0;
  }
`;

export const CopyRight = styled.p`
  color: ${theme.regolarWhite};
  font-weight: 100;
  @media (max-width: ${theme.sizes.tablet}px) {
    margin-top: 32px;
  }
`;

export const Location = styled.a`
  color: ${theme.regolarWhite};
  font-weight: 100;
  font-size: 1em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  @media (max-width: 1700px) {
    padding: 0 0 40px 0;
  }
`;
