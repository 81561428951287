import styled, { theme } from '../../theme/theme';

export const Container = styled.div`
  display: flex;
  justify-content: end;
  height: 80vh;
  min-height: 540px;
  padding-top: 80px;
  @media (max-width: ${theme.sizes.tablet}px) {
    height: auto;
    flex-direction: column;
  }
`;

export const Image = styled.img`
  width: 100%;
  transform: scale(1.1);
  @media (max-width: ${theme.sizes.tablet}px) {
    padding: 0 0 20px 0;
  }
`;

export const RightSide = styled.div`
  display: flex;
  width: 50%;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  @media (max-width: ${theme.sizes.tablet}px) {
    width: 100%;
  }
`;

export const ContactUsButton = styled.button`
  font-weight: 400;
  border-radius: 20px;
  border: 0px;
  background-color: ${theme.deepBlue};
  width: 8rem;
  font-size: 1.125rem;
  padding: 0.75rem;
  -webkit-box-shadow: 0px 1px 5px -1px ${theme.black75};
  -moz-box-shadow: 0px 1px 5px -1px ${theme.black75};
  box-shadow: 0px 1px 5px -1px ${theme.black75};
  color: ${theme.regolarWhite};
  cursor: pointer;
`;

export const Description = styled.p`
  font-weight: 300;
  color: ${theme.regolarWhite};
  font-size: 1.5rem;
`;

export const Title = styled.h1`
  font-weight: 600;
  color: ${theme.regolarWhite};
  font-size: 2.5rem;
  @media (max-width: ${theme.sizes.tablet}px) {
    width: 100%;
  }
`;

export const LeftSide = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 3rem;
  @media (max-width: ${theme.sizes.tablet}px) {
    width: 100%;
  }
`;
