import styled, { css } from 'styled-components';
import { theme } from '../../theme/theme';

export const H3 = styled.h3`
  grid-row-end: span 1;
  grid-column-end: span 1;
  text-align: left;
  font-size: 1.5rem;
  font-weight: 500;

  &.frontEndTitle {
    grid-area: 9 / 1;
  }

  @media (min-width: ${theme.sizes.phone}px) {
    &.frontEndTitle {
      grid-area: none;
    }
    font-size: 1.75rem;
  }

  ${props =>
    props.visible === 'hidden' &&
    css`
      display: none;
    `};
`;
