import React, { Component } from 'react';
import { Element } from 'react-scroll';
import { injectIntl } from 'react-intl';

// Component
import { Strip, Form } from '../';

// Style
import { Container, Title, FormContainer } from './formStrip-styled-component';
import { theme } from '../../theme/theme';

interface FormStripProps {
  intl;
}

class FormStrip extends Component<FormStripProps> {
  constructor(props) {
    super(props);
  }

  render() {
    const { intl } = this.props;
    return (
      <Element name="formStrip" id="formStrip" className="element">
        <Strip
          overrideStyle={`
            background-color: ${theme.lightWhite};
          `}
        >
          <Container>
            <Title>
              {intl.formatMessage({
                id: 'formStripTitle'
              })}
            </Title>
            <FormContainer>
              <Form />
            </FormContainer>
          </Container>
        </Strip>
      </Element>
    );
  }
}
export default injectIntl(FormStrip);
