import React, { Component } from 'react';
import { Element } from 'react-scroll';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';

import { Strip, Repeater } from '../';
import { JobTile } from '../../components';
import { theme } from '../../theme/theme';
import { Container } from './openWorksStrip-styled-component';

interface StripProps {
  intl;
  jobs;
  max: number;
  overrideStyle;
}

class OpenWorksStrip extends Component<StripProps> {
  constructor(props) {
    super(props);
  }

  orderJob = (job, max) => {
    job.sort((a: any, b: any) => {
      const date1 = new Date(a.node.tileInfo.date);
      const date2 = new Date(b.node.tileInfo.date);
      return date1 < date2 ? 1 : date2 < date1 ? -1 : 0;
    });

    return max ? { edges: job.slice?.(0, max) } : { edges: job };
  };

  render() {
    const { jobs, children, overrideStyle, max } = this.props;

    return (
      <Element name="openWorksStrip" id="openWorksStrip" className="element">
        <Strip
          overrideStyle={
            overrideStyle
              ? overrideStyle
              : `
              display: flex;
              padding-bottom: 6em;
              background: ${theme.lightWhite};
              @media (max-width: ${theme.sizes.phone}px) {        
                padding-top: 2.5rem;
              }
					`
          }
        >
          {jobs && jobs.length > 0 ? (
            <Repeater
              title={children ? children : <FormattedMessage id="lookingFor" />}
              list={this.orderJob(jobs, max)}
              objToShow={12}
              objForCol={2}
              tile={JobTile}
              showmore={false}
            />
          ) : (
            <span></span>
          )}
        </Strip>
      </Element>
    );
  }
}

export default injectIntl(OpenWorksStrip);
