import styled from 'styled-components';
import { theme } from '../../theme/theme';

export const BlockQuote = styled.blockquote`
  border-left: 2px solid ${theme.wavelopBlue};
  padding: 0 0 0 25px;
  margin: 25px 0 25px 0;

  p {
    margin: 0;
  }

  @media (max-width: ${theme.sizes.phone}px) {
  }
  @media (max-width: ${theme.sizes.tablet}px) {
  }
`;
