import styled, { theme } from '../../theme/theme';

export const Container = styled.div`
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 50% 50%;
  grid-template-columns: 50% 50%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 0 50px 0;

  // IE11 Workaround for grid layout

  .mainTitle {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
  }

  .whatWeDoDescription {
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
  }

  .whatWeDoSlogan {
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-row-end: span 1;
    grid-column-end: span 2;
  }

  .whatWeDoImage {
    -ms-grid-row: 4;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
  }

  .digitalProductDesignTitle {
    -ms-grid-row: 5;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
  }

  .digitalProductDesignImage {
    -ms-grid-row: 6;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
  }

  .digitalProductDesignDescription {
    -ms-grid-row: 6;
    -ms-grid-row-span: 1;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    display: -ms-flexbox;
    align-items: center;
  }

  .webDevelopmentTitle {
    -ms-grid-row: 7;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
  }

  .frontEndImage {
    -ms-grid-row: 8;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
  }

  .backEndImage {
    -ms-grid-row: 8;
    -ms-grid-row-span: 1;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
  }

  .frontEndTitle {
    -ms-grid-row: 9;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
  }

  .backEndTitle {
    -ms-grid-row: 9;
    -ms-grid-row-span: 1;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
  }

  .frontEndDescription {
    -ms-grid-row: 10;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
  }

  .backEndDescription {
    -ms-grid-row: 10;
    -ms-grid-row-span: 1;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
  }

  .awsTitle {
    -ms-grid-row: 11;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
  }

  .awsDescription {
    -ms-grid-row: 13;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    display: -ms-flexbox;
    align-items: center;
  }

  .awsImage {
    -ms-grid-row: 12;
    -ms-grid-row-span: 1;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
  }

  .otherServicesTitle {
    -ms-grid-row: 13;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
  }

  .hybridMobileDevelopmentImage {
    -ms-grid-row: 14;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
  }

  .chatBotEndImage {
    -ms-grid-row: 14;
    -ms-grid-row-span: 1;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
  }

  .hybridMobileDevelopmentTitle {
    -ms-grid-row: 15;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
  }

  .chatBotTitle {
    -ms-grid-row: 15;
    -ms-grid-row-span: 1;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
  }

  .hybridMobileDevelopmentDescription {
    -ms-grid-row: 16;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
  }

  .chatBotDescription {
    -ms-grid-row: 16;
    -ms-grid-row-span: 1;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
  }

  @media (max-width: ${theme.sizes.phone}px) {
    -ms-grid-columns: 100%;
    grid-template-columns: 100%;

    .whatWeDoSlogan {
      grid-row-end: span 1;
      grid-column-end: span 1;
    }
  }
`;

// .hybridMobileDevelopmentTitle {
//   -ms-grid-row: 11;
//   -ms-grid-row-span: 1;
//   -ms-grid-column: 1;
//   -ms-grid-column-span: 2;
// }

// .hybridMobileDevelopmentDescription {
//   -ms-grid-row: 12;
//   -ms-grid-row-span: 1;
//   -ms-grid-column: 1;
//   -ms-grid-column-span: 1;
//   display: -ms-flexbox;
//   align-items: center;
// }

// .hybridMobileDevelopmentImage {
//   -ms-grid-row: 12;
//   -ms-grid-row-span: 1;
//   -ms-grid-column: 2;
//   -ms-grid-column-span: 1;
// }

// .chatBotEndTitle {
//   -ms-grid-row: 13;
//   -ms-grid-row-span: 1;
//   -ms-grid-column: 1;
//   -ms-grid-column-span: 2;
// }

// .chatBotEndImage {
//   -ms-grid-row: 14;
//   -ms-grid-row-span: 1;
//   -ms-grid-column: 1;
//   -ms-grid-column-span: 1;
// }

// .chatBotDescription {
//   -ms-grid-row: 14;
//   -ms-grid-row-span: 1;
//   -ms-grid-column: 2;
//   -ms-grid-column-span: 1;
//   display: -ms-flexbox;
//   align-items: center;
// }

export const Img = styled.img`
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
`;

export const TitleH2 = styled.h2`
  grid-row-end: span 1;
  grid-column-end: span 2;
  text-align: center;
  font-size: 3rem;
  font-weight: 500;
  padding: 50px 0 0 0;
  @media (max-width: ${theme.sizes.phone}px) {
    grid-column-end: span 1;
    text-align: left;
  }
  &.mainTitle {
    font-size: 3.5rem;
  }
`;

export const TitleH3 = styled.h3`
  grid-row-end: span 1;
  grid-column-end: span 1;
  text-align: center;
  font-size: 2rem;
  font-weight: 500;
  @media (max-width: ${theme.sizes.phone}px) {
    &.frontEndTitle {
      grid-area: 10 / 1;
    }
    &.hybridMobileDevelopmentTitle {
      grid-area: 20 / 1;
    }
    text-align: left;
  }
`;

export const LongDescription = styled.p`
  grid-row-end: span 1;
  grid-column-end: span 2;
  text-align: center;
  width: 70%;
  margin: 0 auto;
  font-size: 1.375rem;
  @media (max-width: ${theme.sizes.phone}px) {
    grid-column-end: span 1;
    width: 100%;
    text-align: left;
  }
`;

export const LongImage = styled(Img)`
  grid-row-end: span 1;
  grid-column-end: span 2;
  width: 50%;
  margin: 0 auto;
  display: flex; // IE11 trick
  @media (max-width: ${theme.sizes.phone}px) {
    grid-column-end: span 1;
    max-width: 400px;
    width: 100%;
  }
  transform: scale(1.2);
`;

export const LeftText = styled.p`
  grid-column-start: 1;
  grid-row-end: span 1;
  grid-column-end: span 1;
  width: 80%;
  margin: 0 auto;
  font-size: 1.375rem;
  @media (max-width: ${theme.sizes.phone}px) {
    &.awsDescription {
      grid-area: 17 / 1;
    }
    &.frontEndDescription {
      grid-area: 11 / 1;
    }
    &.hybridMobileDevelopmentDescription {
      grid-area: 21 / 1;
    }
    width: 100%;
  }
`;

export const RightText = styled.p`
  grid-column-start: 2;
  grid-row-end: span 1;
  grid-column-end: span 1;
  width: 80%;
  margin: 0 auto;
  font-size: 1.375rem;
  @media (max-width: ${theme.sizes.phone}px) {
    grid-column-start: span 1;
    width: 100%;
  }
`;

export const LeftImage = styled(Img)`
  grid-column-start: 1;
  grid-row-end: span 1;
  grid-column-end: span 1;
  transform: scale(1.2);
`;

export const RightImage = styled(Img)`
  grid-column-start: 2;
  grid-row-end: span 1;
  grid-column-end: span 1;
  @media (max-width: ${theme.sizes.phone}px) {
    grid-column-start: span 1;
  }
  transform: scale(1.2);
`;
