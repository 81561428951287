import styled from 'styled-components';
import { theme } from '../../theme/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  text-align: left;
  align-items: center;
  margin: 4rem 0;

  @media (min-width: ${theme.sizes.tablet}px) {
    flex-direction: row;
  }
`;

export const Title = styled.h3`
  font-weight: 400;
  font-size: 1.6rem;
  margin: 0;
  margin-top: 1.5rem;
  @media (min-width: ${theme.sizes.tablet}px) {
    margin-top: 0;
  }
`;

export const Summary = styled.p`
  font-size: 1.375rem;
  font-weight: 300;
`;

export const ImageContainer = styled.div`
  border-radius: 50%;
  padding: 2rem;
  background: ${theme.regolarWhite};
`;

export const InfoContainer = styled.div`
  flex-grow: 2;
  max-width: 100%;
  @media (min-width: ${theme.sizes.tablet}px) {
    margin-left: 4rem;
    paddin: 1rem 0;
  }
`;

export const Icon = styled.img`
  width: 50px;
  height: 50px;
  @media (min-width: ${theme.sizes.tablet}px) {
    width: 80px;
    height: 80px;
  }
  transform: scale(2.5);
`;
