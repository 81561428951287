import React from 'react';
import { FormComponentGroup, Label } from '../formComponents-styled-component';
import { InputComponent } from './input-styled-component';

const Input = props => {
  return (
    <FormComponentGroup>
      <Label htmlFor={props.name}>{props.title}</Label>
      <InputComponent
        classNasme="form-control"
        id={props.name}
        name={props.name}
        type={props.inputtype}
        value={props.value}
        onChange={props.handlechange}
        placeholder={props.placeholder}
        required={props.required}
        {...props}
      />
    </FormComponentGroup>
  );
};

export default Input;
