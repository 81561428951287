import styled, { css } from 'styled-components';
import { theme } from '../../theme/theme';

export const P = styled.p`
  grid-row-end: span 1;
  grid-column-end: span 2;
  text-align: left;
  font-size: 1.125rem;
  width: 100%;
  & li {
    font-size: 1.125rem;
  }

  @media (min-width: ${theme.sizes.phone}px) {
    grid-column-end: span 2;
    width: auto;
    font-size: 1.25rem;

    & li {
      font-size: 1.25rem;
    }
  }

  ${props =>
    props.visible === 'hidden' &&
    css`
      display: none;
    `};
`;
