import styled, { css } from 'styled-components';

export const InputComponent = styled.input`
  display: block;
  margin-bottom: 2rem;
  padding: 0.815rem 1.375rem;
  border: 1px solid #eee;
  border-radius: 8px;
  font-size: 1rem;
`;
