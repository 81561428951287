import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { injectIntl } from 'react-intl';
import _ from 'lodash';

interface HelmetProps {
  intl;
  pageContext;
  pageProps;
}

class HelmetComponent extends Component<HelmetProps> {
  constructor(props) {
    super(props);
  }

  render() {
    const { pageContext, pageProps } = this.props;

    let metadataLabel = pageContext.metadataLabel;

    if (pageContext.generator === 'json') {
      metadataLabel = this._getTranslateMeta(metadataLabel);
    }

    if (metadataLabel == undefined) {
      return <Helmet />;
    }

    let metadata = {
      title: metadataLabel.title,
      meta: [
        {
          name: 'description',
          content: metadataLabel.description
        },
        {
          name: 'keywords',
          content: metadataLabel.keywords
        },
        {
          'http-equiv': 'Content-Type',
          content: 'text/html; charset=utf-8'
        }
      ],
      link: [
        {
          ref: 'canonical',
          href: pageProps.location.href
        }
      ]
    };

    if (
      metadataLabel.hasOwnProperty('robots') &&
      metadataLabel.robots.length > 0
    ) {
      metadata.meta.push({
        name: 'robots',
        content: metadataLabel.robots
      });
    }

    if (
      metadataLabel.hasOwnProperty('socials') &&
      metadataLabel.socials.metaEnabled == true
    ) {
      metadata.meta.push({
        property: 'og:site_name',
        content: metadataLabel.socials.siteName
          ? metadataLabel.socials.siteName
          : 'Wavelop'
      });
      metadata.meta.push({
        property: 'og:type',
        content: metadataLabel.socials.type
      });
      metadata.meta.push({
        property: 'og:title',
        content: metadataLabel.socials.title
      });
      metadata.meta.push({
        property: 'og:description',
        content: metadataLabel.socials.description
      });
      metadata.meta.push({
        property: 'og:url',
        content: pageProps.location.href
      });
      metadata.meta.push({
        property: 'twitter:card',
        content: metadataLabel.socials.card
      });
      metadata.meta.push({
        property: 'twitter:title',
        content: metadataLabel.socials.title
      });
      metadata.meta.push({
        property: 'twitter:description',
        content: metadataLabel.socials.description
      });
      metadata.meta.push({
        property: 'twitter:url',
        content: pageProps.location.href
      });

      let img = 'https://wavelop.com/images/wavelop-logo.png';
      let defImgW, imgW;
      defImgW = imgW = '774px';
      let imgH, defImgH;
      imgH = defImgH = '774px';

      if (
        metadataLabel.socials.hasOwnProperty('image') &&
        metadataLabel.socials.image
      ) {
        if (metadataLabel.socials.image.hasOwnProperty('childImageSharp')) {
          img =
            pageProps.location.origin +
            metadataLabel.socials.image.childImageSharp.original.src;
        } else {
          if (metadataLabel.socials.image.indexOf('http') < 0)
            img = pageProps.location.origin + metadataLabel.socials.image;
          else img = metadataLabel.socials.image;
        }
      } else if (
        metadataLabel.socials.hasOwnProperty('imageUrl') &&
        metadataLabel.socials.imageUrl
      ) {
        if (metadataLabel.socials.imageUrl.indexOf('http') < 0)
          img = pageProps.location.origin + metadataLabel.socials.imageUrl;
        else img = metadataLabel.socials.imageUrl;
      }

      imgH = metadataLabel.socials.hasOwnProperty('imageHeigth')
        ? metadataLabel.socials.imageHeigth
        : defImgH;

      imgW = metadataLabel.socials.hasOwnProperty('imageWidth')
        ? metadataLabel.socials.imageWidth
        : defImgW;

      metadata.meta.push({
        property: 'twitter:image',
        content: img
      });

      metadata.meta.push({
        property: 'twitter:image:width',
        content: imgW
      });

      metadata.meta.push({
        property: 'twitter:image:height',
        content: imgH
      });

      metadata.meta.push({
        property: 'og:image',
        content: img
      });

      metadata.meta.push({
        property: 'og:image:width',
        content: imgW
      });

      metadata.meta.push({
        property: 'og:image:height',
        content: imgH
      });

      metadata.meta.push({
        property: 'twitter:site',
        content: '@wavelop'
      });

      metadata.meta.push({
        property: 'twitter:creator',
        content: '@wavelop'
      });
    }

    if (pageContext.localizePaths) {
      let keys = Object.keys(pageContext.localizePaths);
      // eslint-disable-next-line
      keys.map(l => {
        if (pageContext.language !== l) {
          metadata.link.push({
            rel: 'alternate',
            href: pageProps.location.origin + pageContext.localizePaths[l],
            hrefLang: l
          });
        }
      });
    }

    return (
      <Helmet title={metadata.title} meta={metadata.meta} link={metadata.link}>
        <html lang={pageContext.language} />
      </Helmet>
    );
  }

  _getTranslateMeta(meta) {
    const { intl } = this.props;

    let obj = {
      title: intl.formatMessage({
        id: _.get(meta, 'title', '404 Not Found | Wavelop')
      }),
      description: intl.formatMessage({
        id: _.get(
          meta,
          'description',
          'Wavelop 404 page | An innovative design and development software house '
        )
      }),
      keywords: intl.formatMessage({
        id: _.get(meta, 'keywords', 'web agency, web app makers, wavelop')
      })
    };

    if (meta.hasOwnProperty('robots')) {
      obj['robots'] = _.get(meta, 'robots', '');
    }

    if (meta.hasOwnProperty('socials')) {
      obj['socials'] = {
        metaEnabled: true
      };

      if (meta.socials.hasOwnProperty('image')) {
        obj['socials']['image'] = meta.socials.image;
      }
      if (meta.socials.hasOwnProperty('imageWidth')) {
        obj['socials']['imageWidth'] = meta.socials.imageWidth;
      }
      if (meta.socials.hasOwnProperty('imageHeigth')) {
        obj['socials']['imageHeigth'] = meta.socials.imageHeigth;
      }

      obj['socials']['siteName'] = meta.socials.siteName
        ? meta.socials.siteName
        : 'Wavelop';
      obj['socials']['type'] = meta.socials.type;

      obj['socials']['title'] = intl.formatMessage({
        id: _.get(meta, 'socials.title', '')
      });

      obj['socials']['description'] = intl.formatMessage({
        id: _.get(meta, 'socials.description', '')
      });

      obj['socials']['card'] = _.get(meta, 'socials.card', '');
    }

    return obj;
  }
}

export default injectIntl(HelmetComponent);
