import styled, { css } from 'styled-components';
import { theme } from '../../theme/theme';
import { H3 } from '../Headline3/headline3-styled-component';
import { H4 } from '../Headline4/headline4-styled-component';

export const Title = styled(H3)`
  margin: 0;
  margin-bottom: 0.375rem;
  @media (min-width: ${theme.sizes.phone}px) {
    font-size: 1.5rem;
  }
`;

export const Summary = styled(H4)`
  margin: 0;
  margin-bottom: 0.375rem;
  text-align: left;
  font-weight: 300;
  width: 100%;
  @media (min-width: ${theme.sizes.phone}px) {
    font-size: 1.375rem;
  }
`;

export const Date = styled.span`
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: uppercase;
  @media (min-width: ${theme.sizes.phone}px) {
    font-size: 1.75rem;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 0;
  margin-bottom: 1.5rem;
  padding: 0;
  text-align: center;
  max-width: 100%;
  position: relative;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;

  @media (min-width: ${theme.sizes.phone}px) {
    ${props =>
      props.cover === true &&
      css`
        width: 100%;
        margin: 2rem 0;
      `};

    ${props =>
      props.cover === true &&
      css`
        background: ${theme.lightWhite};
        margin-bottom: 4em;
      `};

    ${props =>
      props.column &&
      css`
        -ms-grid-column: ${props.column * 2 + 1};
      `};

    ${props =>
      props.row &&
      css`
        -ms-grid-row: ${props.row};
      `};
  }
`;
