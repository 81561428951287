import { Link as GatsbyLink } from 'gatsby';
import styled, { theme } from '../../theme/theme';

export const LanguageSwitcher = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Link = styled(GatsbyLink)`
  text-decoration: none;
  color: ${props =>
    props.locale == props.language ? theme.gray : theme.regolarWhite};
  padding: 0 10px 0 0;
  :hover {
    text-decoration: ${props =>
      props.locale == props.language ? 'none' : 'underline'};
  }
`;

export const Img = styled.img`
  width: 25px;
  padding-right: 10px;
`;
