import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import _ from 'lodash';

import { LinkA } from '..';

import { Container, Separator } from './breadcrumbs-styled-component';

const Breadcrumbs = props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          pages: allMarkdownRemark {
            edges {
              node {
                id
                frontmatter {
                  path
                  title
                  shortTitle
                  language
                }
              }
            }
          }
        }
      `}
      render={data => parseData(data, props)}
    />
  );
};

const parseData = (data, props) => {
  const allpages = _.filter(data.pages.edges, edge => {
    if (edge.node.frontmatter.language == props.pageContext.language)
      return edge.node.frontmatter;
    return false;
  });

  const currentPath = props.pageContext.slug;
  const paths = _.filter(currentPath.split('/'), obj => {
    return obj.length > 0;
  });

  let builder = '/';
  const paths2Verify = [];
  for (let i = 0; i < paths.length - 1; i++) {
    const p = paths[i];
    if (i == 0 && p !== props.pageContext.language)
      paths2Verify.push({ path: builder, label: 'Home' });
    builder += `${p}/`;
    if (i == 0 && p === props.pageContext.language)
      paths2Verify.push({ path: builder, label: 'Home' });
    else paths2Verify.push({ path: builder, label: null });
  }

  _.each(paths2Verify, obj => {
    if (obj.label == null) {
      let page = _.find(allpages, o => {
        return o.node.frontmatter.path == obj.path;
      });
      if (page) {
        page = page.node.frontmatter;
        obj.label = page.shortTitle || page.title.split('|')[0].trim();
      }
    }
  });

  return (
    <Container>
      {paths2Verify.map(p => (
        <span key={p.label}>
          <LinkA title={p.label} href={p.path}>
            {p.label}
          </LinkA>
          <Separator>/</Separator>
        </span>
      ))}
      <span>
        {props.pageContext.metadataLabel.shortTitle
          ? props.pageContext.metadataLabel.shortTitle
          : props.pageContext.metadataLabel.title.split('|')[0].trim()}
      </span>
    </Container>
  );
};

export default Breadcrumbs;
