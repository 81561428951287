import React from 'react';

import { TableContainer } from './table-styled-component';

const Table = props => {
  return (
    <TableContainer>
      <table>{props.children}</table>
    </TableContainer>
  );
};

export default Table;
