import styled from 'styled-components';

export const StripWrapper = styled.section`
  display: grid;
  grid-template-columns: auto;
`;

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  @media (max-width: 1200px) {
    width: calc(100% - 40px);
  }
`;
