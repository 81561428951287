import styled, { theme } from '../../theme/theme';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

    html,
	body {
		position: relative;
		min-height: 100vh;
	}
	
	#___gatsby {
		position: relative;
		min-height: 100vh;
	}

	/**
	 * https://github.com/gatsbyjs/gatsby/issues/7310
	**/

	#___gatsby > div {
		position: relative;
		min-height: 100vh;
	}

    .headroom {
        transition: background 1000ms easy-in;
        box-shadow: none;
        background: linear-gradient(0.25turn, ${theme.deepBlue}, ${theme.aqua});
    }
    .headroom--unpinned {
        transition: none !important; /* https://github.com/KyleAMathews/react-headroom/issues/154 */
    }
    .headroom.headroom--pinned.headroom--scrolled {
        -webkit-box-shadow: 0px 1px 5px -1px ${theme.black75};
        -moz-box-shadow: 0px 1px 5px -1px ${theme.black75};
        box-shadow: 0px 1px 5px -1px ${theme.black75};
    }
`;
export const ContentStrip = styled.div`
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 6rem;

  .nano-video {
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    padding-top: 30px;
    position: relative;
  }
  .nano-video iframe,
  .nano-video object,
  .nano-video embed {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  @media (min-width: ${theme.sizes.desktop}px) {
    & .gatsby-resp-image-link {
      margin: 2rem -200px;
      width: auto;
      height: auto;
    }
  }
`;
