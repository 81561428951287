import React from 'react';

import { BlockQuote } from './blockquote-styled-component';

const Blockquote = props => {
  return (
    <BlockQuote>
      <i>{props.children}</i>
    </BlockQuote>
  );
};

export default Blockquote;
