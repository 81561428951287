import React, { Component } from 'react';
import { Element } from 'react-scroll';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';

import { Strip, Repeater, StoryTile } from '../';
import { theme } from '../../theme/theme';

import {
  Container,
  TitleH2,
  LongDescription
} from './newsStrip-styled-component';

interface StripProps {
  intl;
  stories;
}

class NewsStrip extends Component<StripProps> {
  constructor(props) {
    super(props);
  }

  render() {
    const { stories } = this.props;

    return (
      <Element name="newsStrip" id="newsStrip" className="element">
        <Strip
          overrideStyle={`
            background: #3b5a9c; /* Old browsers */
            background: -moz-linear-gradient(top, #3b5a9c 21%, #f5f5f5 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top, #3b5a9c 21%,#f5f5f5 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, #3b5a9c 21%,#f5f5f5 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3b5a9c', endColorstr='#f5f5f5',GradientType=0 ); /* IE6-9 */
        `}
        >
          <Container>
            <TitleH2 className={`title`}>
              <FormattedHTMLMessage id="newsStripTitle" />
            </TitleH2>
            <LongDescription className={`description`}>
              <FormattedHTMLMessage id="newsStripDescription" />
            </LongDescription>

            {stories && stories.edges.length > 0 && (
              <Repeater
                list={stories}
                objToShow={3}
                objForCol={3}
                tile={StoryTile}
                showmore={false}
                className={`repeaterTile`}
              />
            )}
          </Container>
        </Strip>
      </Element>
    );
  }
}

export default injectIntl(NewsStrip);
