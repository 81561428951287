import React, { Component } from 'react';

import {
  LanguageSwitcher as LanguageSwitcherContainer,
  Link,
  Img
} from './languageSwitcher-styled-component';
import World from '../../assets/images/world.svg';
import { injectIntl } from 'react-intl';
interface LanguageSwitcherProps {
  intl;
  pageProps;
}

class LanguageSwitcher extends Component<LanguageSwitcherProps> {
  constructor(props) {
    super(props);
  }

  renderLanguageChoice({ code, label }) {
    if (
      this.props.pageProps.pageContext.hasOwnProperty('localizePaths') &&
      this.props.pageProps.pageContext.localizePaths.hasOwnProperty(code)
    ) {
      let key = this.props.pageProps.pageContext.localizePaths[code];

      let locale = this.props.pageProps.pageContext.language;

      return code !== locale ? (
        <Link
          key={`${key}`}
          to={`${key}`}
          language={`${code}`}
          locale={`${locale}`}
        >
          {label}
        </Link>
      ) : (
        ''
      );
    } else {
      return '';
    }
  }

  render() {
    const { intl } = this.props;
    const languages = [
      { code: 'en', label: intl.formatMessage({ id: 'languageEn' }) },
      { code: 'it', label: intl.formatMessage({ id: 'languageIt' }) }
    ];

    return (
      <LanguageSwitcherContainer>
        <Img
          src={World}
          alt={intl.formatMessage({ id: 'languageChoiceText' })}
        />
        {languages.map(language => this.renderLanguageChoice(language))}
      </LanguageSwitcherContainer>
    );
  }
}

export default injectIntl(LanguageSwitcher);
