import React, { Component } from 'react';
import nprogress from 'nprogress';
import Layout from '../Layout';

import {
  CoverStrip,
  FindUsStrip,
  FormStrip,
  MethodStrip,
  NewsStrip,
  OpenWorksHomePageStrip,
  ServicesStrip
} from '../';

import withIntl from '../../i18n/withIntl';

interface HomePageProps {
  pageContext;
  stories;
  jobs;
}

class HomePage extends Component<HomePageProps, {}> {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    if (typeof document !== 'undefined') {
      nprogress.done();
    }
  }

  public render() {
    const { stories, jobs } = this.props;

    return (
      <Layout {...this.props}>
        <CoverStrip name="CoverStrip" />
        <ServicesStrip name="ServicesStrip" />
        <MethodStrip name="MethodStrip" />
        <NewsStrip name="NewsStrip" stories={stories} />
        <OpenWorksHomePageStrip
          name="OpenWorksStrip"
          jobs={jobs}
        ></OpenWorksHomePageStrip>
        <FormStrip name="FormStrip" />
        <FindUsStrip name="FindUsStrip" />
      </Layout>
    );
  }
}

export default withIntl(HomePage);
