import styled from 'styled-components';
import { theme } from '../../theme/theme';

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  text-align: center;
  max-width: 865px;
  margin: 0 auto;
  padding-bottom: 2rem;
  color: ${theme.regolarWhite};
`;

export const Title = styled.h2`
  grid-row-end: span 1;
  grid-column-end: span 2;
  text-align: center;
  font-size: 3rem;
  font-weight: 500;
  padding: 50px 0 0 0;
  @media (max-width: ${theme.sizes.phone}px) {
    grid-column-end: span 1;
    text-align: left;
  }
  @media (max-width: ${theme.sizes.tablet}px) {
    width: 100%;
  }
`;

export const SubTitle = styled.p`
  font-weight: 300;
  font-size: 1.375rem;
  @media (max-width: ${theme.sizes.tablet}px) {
    text-align: left;
  }
`;
