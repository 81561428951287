import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';
import { theme } from '../../theme/theme';

export const LinkStyled = styled(GatsbyLink)`
  text-decoration: none;
  color: ${theme.deepBlue};
  padding: 0 10px 0 0;
  :hover {
    text-decoration: underline;
  }
`;
