import styled from 'styled-components';

export const TextAreaComponent = styled.textarea`
  display: block;
  margin-bottom: 2rem;
  padding: 0.815rem 1.375rem;
  border: 1px solid #eee;
  border-radius: 8px;
  resize: none;
`;
