import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import RehypeReact from 'rehype-react';
import { theme } from '../../theme/theme';

import withIntl from '../../i18n/withIntl';

import {
  Layout,
  Strip,
  Headline1,
  Headline2,
  Headline3,
  Headline4,
  Paragraph,
  StoryTile,
  Repeater,
  Blockquote,
  Table,
  LinkA,
  List,
  Breadcrumbs
} from '../../components';

import {
  GlobalStyle,
  Container,
  BlogStrip,
  Title,
  MetaInfo,
  Tag,
  Summary
} from './story-styled-component';

interface PageProps {
  pageContext;
  data;
}

const renderAst = new RehypeReact({
  createElement: React.createElement,
  components: {
    h1: Headline1,
    h2: Headline2,
    h3: Headline3,
    h4: Headline4,
    p: Paragraph,
    blockquote: Blockquote,
    table: Table,
    a: LinkA,
    ul: List,
    ol: List
  }
}).Compiler;

class Story extends Component<PageProps, {}> {
  constructor(props) {
    super(props);
  }

  orderStories = originalStories => {
    originalStories.sort((a, b) => {
      const date1 = new Date(a.node.tileInfo.meta.date);
      const date2 = new Date(b.node.tileInfo.meta.date);
      return date1 < date2 ? 1 : date2 < date1 ? -1 : 0;
    });

    return { edges: originalStories };
  };

  public render() {
    const { markdownRemark, related: originalStories } = this.props.data;
    const storyMetaData = markdownRemark.tileInfo.meta;
    const related =
      originalStories &&
      originalStories.edges &&
      originalStories.edges.length > 0
        ? this.orderStories(originalStories.edges)
        : { edges: [] };

    return (
      <Layout {...this.props}>
        <GlobalStyle />
        <Container>
          <Strip overrideStyle={`display: flex;padding-top: 2rem;`}>
            <Img fluid={storyMetaData.coverImage.childImageSharp.fluid} />
          </Strip>
          <Strip
            overrideStyle={`
							display: flex;
						`}
          >
            <BlogStrip>
              <Title>{storyMetaData.title}</Title>
              <Breadcrumbs {...this.props} />
              <MetaInfo>
                {storyMetaData.author ? `${storyMetaData.author} - ` : ''}
                {storyMetaData.date}
              </MetaInfo>
              <div>
                {storyMetaData.tags &&
                  storyMetaData.tags.length > 0 &&
                  storyMetaData.tags.map(node => (
                    <Tag key={node.tag}>{`#${node.tag}`}</Tag>
                  ))}
              </div>
              <Summary>{storyMetaData.summary}</Summary>

              {renderAst(markdownRemark.htmlAst)}
            </BlogStrip>
          </Strip>
          {related && related.edges.length > 0 && (
            <Strip
              overrideStyle={`
							display: flex;
							padding: 6rem 0 6em 0;
							background-color: ${theme.lightWhite}
						`}
            >
              <Repeater
                list={related}
                objToShow={3}
                objForCol={3}
                tile={StoryTile}
                showmore={false}
              />
            </Strip>
          )}
        </Container>
      </Layout>
    );
  }
}

export default withIntl(Story);

export const pageQuery = graphql`
  query($path: String!, $language: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      htmlAst
      ...StoryTileFragment
    }

    related: allMarkdownRemark(
      filter: {
        frontmatter: {
          view: { eq: "story" }
          path: { ne: $path }
          language: { eq: $language }
        }
      }
      sort: { fields: [frontmatter___storyMetaData___date], order: DESC }
      limit: 3
    ) {
      edges {
        node {
          id
          ...StoryTileFragment
        }
      }
    }
  }
`;
