import styled, { theme } from '../../theme/theme';
import { createGlobalStyle } from 'styled-components';

export const Container = styled.div`
	display: flex;
	align-center: center;
	flex-direction: column;
	height: calc(60vh - 80px);
	align-items: center;
`;

export const h1 = styled.h1`
	text-align: center;
	font-size: 3rem;
`;

export const p = styled.p`
	text-align: center;
	font-size: 1.5rem;
`;

export const GlobalStyle = createGlobalStyle`
    .headroom {
        transition: background 1000ms easy-in;
        box-shadow: none;
        background: linear-gradient(0.25turn, ${theme.deepBlue}, ${theme.aqua});
    }
    .headroom--unpinned {
        transition: none !important; /* https://github.com/KyleAMathews/react-headroom/issues/154 */
    }
    .headroom.headroom--pinned.headroom--scrolled {
        -webkit-box-shadow: 0px 1px 5px -1px ${theme.black75};
        -moz-box-shadow: 0px 1px 5px -1px ${theme.black75};
        box-shadow: 0px 1px 5px -1px ${theme.black75};
    }
`;
