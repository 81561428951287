import React, { Component } from 'react';
import { Element } from 'react-scroll';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';

import { MethodTile, Strip } from '../';

import { theme } from '../../theme/theme';
import { Container, Title, SubTitle } from './methodStrip-styled-component';

import M1Icon from '../../assets/images/8.svg';
import M2Icon from '../../assets/images/9.svg';
import M3Icon from '../../assets/images/10.svg';
import M4Icon from '../../assets/images/11.svg';
import M5Icon from '../../assets/images/12.svg';
import M6Icon from '../../assets/images/13.svg';

interface StripProps {
  intl;
}

interface StripState {
  methods;
}

class MethodStrip extends Component<StripProps, StripState> {
  constructor(props) {
    super(props);
    const { intl } = this.props;

    this.state = {
      methods: [
        {
          icon: M1Icon,
          alt: intl.formatMessage({ id: 'methodStripM1IconAlt' }),
          title: 'methodStripM1Title',
          summary: 'methodStripM1Summary'
        },
        {
          icon: M2Icon,
          alt: intl.formatMessage({ id: 'methodStripM2IconAlt' }),
          title: 'methodStripM2Title',
          summary: 'methodStripM2Summary'
        },
        {
          icon: M3Icon,
          alt: intl.formatMessage({ id: 'methodStripM3IconAlt' }),
          title: 'methodStripM3Title',
          summary: 'methodStripM3Summary'
        },
        {
          icon: M4Icon,
          alt: intl.formatMessage({ id: 'methodStripM4IconAlt' }),
          title: 'methodStripM4Title',
          summary: 'methodStripM4Summary'
        },
        {
          icon: M5Icon,
          alt: intl.formatMessage({ id: 'methodStripM5IconAlt' }),
          title: 'methodStripM5Title',
          summary: 'methodStripM5Summary'
        },
        {
          icon: M6Icon,
          alt: intl.formatMessage({ id: 'methodStripM6IconAlt' }),
          title: 'methodStripM6Title',
          summary: 'methodStripM6Summary'
        }
      ]
    };
  }

  render() {
    const { intl } = this.props;

    return (
      <Element name="methodStrip" id="methodStrip" className="element">
        <Strip
          overrideStyle={`
                        background: ${theme.wavelopBlue};
                        justify-content: center;
                    `}
        >
          <Container>
            <Title>{intl.formatMessage({ id: 'methodStripTitle' })}</Title>
            <SubTitle>
              <FormattedHTMLMessage id="methodStripSubtitle" />
            </SubTitle>
            <div>
              {this.state.methods.map(m => {
                return (
                  <MethodTile
                    key={m.title}
                    title={m.title}
                    icon={m.icon}
                    alt={m.alt}
                    summary={m.summary}
                  />
                );
              })}
            </div>
          </Container>
        </Strip>
      </Element>
    );
  }
}

export default injectIntl(MethodStrip);
