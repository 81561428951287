import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { theme } from '../../theme/theme';
import { H2 } from '../Headline2/headline2-styled-component';
import { P } from '../Paragraph/paragraph-styled-component';

export const Title = styled(H2)`
  margin: 0;
  margin-bottom: 0.375rem;
  .text--scale-fs & {
    font-size: 1.75rem;
  }
`;

export const Summary = styled(P)`
  margin: 0;
  margin-bottom: 0.375rem;
  .text--scale-fs & {
    font-size: 1.125rem;
  }
`;

export const Date = styled(P)`
  color: ${theme.grayDarker};
  margin: 0;
  margin-bottom: 0.375rem;
  font-size: 0.85rem !important;
`;

export const CoverImage = styled(Img)`
  width: 100%;
  height: auto;
  min-height: 200px;

  &.col2 {
    min-height: 320px;
    max-height: 320px;
  }

  &.col3 {
    min-height: 220px;
    max-height: 220px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 0;
  margin-bottom: 1.5rem;
  padding: 0;
  text-align: center;
  max-width: 100%;
  position: relative;
  cursor: pointer;
  background: ${theme.lightWhite};
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;

  /* IE11 fix: coordinateds of cell must be always calculated*/
  ${props =>
    props.y &&
    props.x &&
    css`
      -ms-grid-row: ${props.y[1]};
      -ms-grid-column: ${props.x[1]};
    `}

  /*IE11 fix: cell of css grid have 1x1 dimension in our case*/
	-ms-grid-row-span: 1;
  -ms-grid-column-span: 1;

  @media all and (-ms-high-contrast: none) {
    margin: 0 50px 50px 0;
  }

  @media (min-width: ${theme.sizes.phone}px) {
    background: ${theme.regolarWhite};
    ${props =>
      props.y &&
      props.x &&
      css`
        -ms-grid-row: ${props.y[2]};
        -ms-grid-column: ${props.x[2]};
      `}
    ${props =>
      props.cover === true &&
      css`
        width: 100%;
        margin: 2rem 0;
      `};

    ${props =>
      props.cover === true &&
      css`
        background: ${theme.lightWhite};
        margin-bottom: 4em;
      `};

    ${props =>
      props.column &&
      css`
        -ms-grid-column: ${props.column * 2 + 1};
      `};

    ${props =>
      props.row &&
      css`
        -ms-grid-row: ${props.row};
      `};
  }

  @media (min-width: ${theme.sizes.desktop}px) {
    ${props =>
      props.y &&
      props.x &&
      props.col &&
      props.col == 'col3' &&
      css`
        -ms-grid-row: ${props.y[3]};
        -ms-grid-column: ${props.x[3]};
      `}

    ${props =>
      props.y &&
      props.x &&
      props.col &&
      props.col == 'col4' &&
      css`
        -ms-grid-row: ${props.y[4]};
        -ms-grid-column: ${props.x[4]};
      `}
  }
`;

export const InfoContainer = styled.div`
  box-sizing: border-box;
  background: ${theme.regolarWhite};
  width: 100%;
  padding: 2rem 1.5rem;
  @media (min-width: ${theme.sizes.phone}px) {
    ${props =>
      props.cover === true &&
      css`
        max-width: calc(50% - 24px);
        margin-top: -80px;
        margin-left: 2rem;
        position: relative;
      `};
  }
`;

export const TileLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  width: 100%;
  height: 100%;
`;
