import React, { Component } from 'react';
import { graphql } from 'gatsby';

import HomePageComponent from '../../components/HomePage';
import withIntl from '../../i18n/withIntl';

interface HomePageProps {
  pageContext;
  data;
}

class HomePage extends Component<HomePageProps, {}> {
  constructor(props) {
    super(props);
  }

  orderStories = originalStories => {
    originalStories.sort((a, b) => {
      const date1 = new Date(a.node.tileInfo.meta.date);
      const date2 = new Date(b.node.tileInfo.meta.date);
      return date1 < date2 ? 1 : date2 < date1 ? -1 : 0;
    });

    return { edges: originalStories };
  };

  orderJob = job => {
    job.sort((a, b) => {
      const date1 = new Date(a.node.tileInfo.date);
      const date2 = new Date(b.node.tileInfo.date);
      return date1 < date2 ? 1 : date2 < date1 ? -1 : 0;
    });

    return { edges: job };
  };

  public render() {
    const { stories: originalStories, jobs } = this.props.data;

    const stories =
      originalStories &&
      originalStories.edges &&
      originalStories.edges.length > 0
        ? this.orderStories(originalStories.edges)
        : { edges: [] };

    let topStories = {
      edges:
        stories && stories.edges && stories.edges.length > 0
          ? stories.edges.slice(0, 3)
          : []
    };

    const jobsActive =
      jobs &&
      jobs.edges.length > 0 &&
      jobs.edges.filter((job: any) => job.node.tileInfo.validity);

    return (
      <HomePageComponent
        {...this.props}
        stories={topStories}
        jobs={jobsActive}
      />
    );
  }
}

export default withIntl(HomePage);

export const pageQuery = graphql`
  query($language: String!) {
    stories: allMarkdownRemark(
      filter: {
        frontmatter: { view: { eq: "story" }, language: { eq: $language } }
      }
    ) {
      edges {
        node {
          id
          ...StoryTileFragment
        }
      }
    }
    jobs: allMarkdownRemark(
      filter: {
        frontmatter: { view: { eq: "job" }, language: { eq: $language } }
      }
    ) {
      edges {
        node {
          id
          ...JobTileFragment
        }
      }
    }
  }
`;
