import styled, { css } from 'styled-components';

export const SubmissionMessageComponent = styled.div`
  display: flex;
  background-color: #eee;
  padding: 1.25rem;
  color: #444;
  font-size: 1rem;
  border-radius: 8px;

  ${props =>
    props.type === 'hide' &&
    css`
      display: none;
    `};
  ${props =>
    props.type === 'success' &&
    css`
      background-color: #c3e6cb;
      color: #155724;
    `};
  ${props =>
    props.type === 'error' &&
    css`
      background-color: #f8d7da;
      color: #721c24;
    `};
  ${props =>
    props.type === 'info' &&
    css`
      background-color: #cce5ff;
      color: #004085;
    `};
`;
