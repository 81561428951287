import React from 'react';

import {
  Container,
  InfoContainer,
  Title,
  Summary,
  Icon,
  TileLink
} from './jobtile-styled-component';

const JobTile = props => {
  const { data, cover, col, x, y } = props;
  return (
    <Container cover={cover} col={col} x={x} y={y}>
      <TileLink to={data.path}>
        <InfoContainer className={cover ? '' : 'text--scale-fs'} cover={cover}>
          <Icon src={data.icon.publicURL} />
          <Title>{data.title}</Title>
          <Summary>{data.description}</Summary>
        </InfoContainer>
      </TileLink>
    </Container>
  );
};

export default JobTile;
