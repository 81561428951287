import styled from 'styled-components';
import { theme } from '../../../../theme/theme';

export const SubmitComponent = styled.input`
  cursor: pointer;
  margin-bottom: 2rem;
  padding: 0.815rem 1.375rem;
  color: ${theme.regolarWhite};
  border: 1px solid ${theme.deepBlue};
  background-color: ${theme.deepBlue};
  border-radius: 16px;
  font-size: 1.2rem;
  -webkit-box-shadow: 0px 1px 5px -1px ${theme.black75};
  -moz-box-shadow: 0px 1px 5px -1px ${theme.black75};
  box-shadow: 0px 1px 5px -1px ${theme.black75};
`;
