import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';

import { LanguageSwitcher, Strip } from '../';

import {
  Container,
  LeftSide,
  RightSide,
  LogoImg,
  FooterLinks,
  FooterLinkSection,
  FooterLinkTitle,
  FooterLinkExternal,
  FooterLinkInternal,
  CopyRight,
  Location,
  Slogan
} from './footer-styled-component';

import { theme } from '../../theme/theme';
import WavelopLogo from '../../assets/images/wavelop-icon_white.svg';

interface FooterProps {
  intl;
  pageProps;
}

class Footer extends Component<FooterProps> {
  constructor(props) {
    super(props);

    this.state = {
      items: {
        it: [
          {
            title: 'companyInformation',
            to: '/it/informazioni-societarie/'
          },
          {
            title: 'privacy',
            to: '/it/privacy-cookies/'
          },
          {
            title: 'stories',
            to: '/it/story/'
          },
          {
            title: 'jobs',
            to: '/it/lavora-con-noi/'
          }
        ],
        en: [
          {
            title: 'companyInformation',
            to: '/en/company-informations/'
          },
          {
            title: 'privacy',
            to: '/en/privacy-cookies/'
          },
          {
            title: 'stories',
            to: '/en/story/'
          },
          {
            title: 'jobs',
            to: '/en/join-us/'
          },
          {
            title: 'location',
            to: '/en/join-us/'
          }
        ]
      }
    };
  }
  render() {
    const { intl } = this.props;
    let menuItem = this.state.items[intl.locale];

    return (
      <footer>
        <Strip
          overrideStyle={`
                        background-color: ${theme.lightBlack};
                    `}
        >
          <div>
            <Container>
              <LeftSide>
                <LogoImg src={WavelopLogo} alt="Wavelop logo" />
                <Slogan>
                  <FormattedMessage id="footerSlogan" />
                </Slogan>
              </LeftSide>
              <RightSide>
                <FooterLinks>
                  <FooterLinkSection>
                    <FooterLinkTitle>Blog</FooterLinkTitle>
                    <FooterLinkInternal
                      to={menuItem[2].to}
                      title={intl.formatMessage({
                        id: menuItem[2].title
                      })}
                    >
                      <FormattedMessage id={menuItem[2].title} />
                    </FooterLinkInternal>
                    <FooterLinkExternal
                      rel="noopener"
                      href="https://medium.com/@wavelop"
                    >
                      Medium
                    </FooterLinkExternal>
                  </FooterLinkSection>
                  <FooterLinkSection>
                    <FooterLinkTitle>Dev</FooterLinkTitle>
                    <FooterLinkExternal
                      rel="noopener"
                      href="https://github.com/Wavelop"
                    >
                      Github
                    </FooterLinkExternal>
                    <FooterLinkExternal
                      rel="noopener"
                      href="https://codepen.io/wavelop/"
                    >
                      Codepen
                    </FooterLinkExternal>
                  </FooterLinkSection>
                  <FooterLinkSection>
                    <FooterLinkTitle>Follow</FooterLinkTitle>
                    <FooterLinkExternal
                      rel="noopener"
                      href="https://www.linkedin.com/company/wavelop/"
                    >
                      Linkedin
                    </FooterLinkExternal>
                    <FooterLinkExternal
                      rel="noopener"
                      href="https://www.facebook.com/wavelop/"
                    >
                      Facebook
                    </FooterLinkExternal>
                    <FooterLinkExternal
                      rel="noopener"
                      href="https://twitter.com/wavelop/"
                    >
                      Twitter
                    </FooterLinkExternal>
                    <FooterLinkExternal
                      rel="noopener"
                      href="https://www.instagram.com/wavelop_com/"
                    >
                      Instagram
                    </FooterLinkExternal>
                  </FooterLinkSection>
                  <FooterLinkSection>
                    <FooterLinkTitle>Legal</FooterLinkTitle>
                    <FooterLinkInternal
                      to={menuItem[1].to}
                      title={intl.formatMessage({
                        id: menuItem[1].title
                      })}
                    >
                      <FormattedMessage id={menuItem[1].title} />
                    </FooterLinkInternal>
                    <FooterLinkInternal
                      to={menuItem[0].to}
                      title={intl.formatMessage({
                        id: menuItem[0].title
                      })}
                    >
                      <FormattedMessage id={menuItem[0].title} />
                    </FooterLinkInternal>
                    <FooterLinkInternal
                      to={menuItem[3].to}
                      title={intl.formatMessage({
                        id: menuItem[3].title
                      })}
                    >
                      <FormattedMessage id={menuItem[3].title} />
                    </FooterLinkInternal>
                  </FooterLinkSection>
                </FooterLinks>
              </RightSide>
            </Container>
            <Container className={'bottom'}>
              <LeftSide>
                <LanguageSwitcher pageProps={this.props.pageProps} />
                <CopyRight>
                  <FormattedMessage id="footerCredits" />
                </CopyRight>
              </LeftSide>
              <RightSide>
                <Location
                  rel="noopener"
                  href="https://g.page/r/CcpIa3DWUHsSEAE"
                >
                  <FormattedMessage id="footerPlace" />
                </Location>
              </RightSide>
            </Container>
          </div>
        </Strip>
      </footer>
    );
  }
}

export default injectIntl(Footer);
