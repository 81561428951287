import React, { Component } from 'react';
import styled from 'styled-components';

import { StripWrapper, Container } from './strip-styled-component';

interface StripProps {
  overrideStyle: Partial<string>;
  children: JSX.Element;
}

class Strip extends Component<StripProps> {
  constructor(props) {
    super(props);
  }

  render() {
    const ExtendendStripWrapper = styled(StripWrapper)`
      ${this.props.overrideStyle}
    `;

    return (
      <ExtendendStripWrapper>
        <Container>{this.props.children}</Container>
      </ExtendendStripWrapper>
    );
  }
}

export default Strip;
