import React, { Component } from 'react';
import chunk from 'lodash/chunk';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';
import {
  Container,
  Loader,
  LoaderContainer,
  Title
} from './repeater-styled-component';

interface RepeaterProps {
  list;
  title;
  objToShow;
  objForCol;
  tile;
  showmore;
  intl;
  className;
}

interface RepeaterState {
  showingMore: boolean;
  objToShow;
}

class Repeater extends Component<RepeaterProps, RepeaterState> {
  ticking = false;
  objToShow = 0;

  constructor(props) {
    super(props);

    this.ticking = false;

    let objToShow = (this.objToShow = this.props.objToShow);
    this.state = {
      showingMore: false,
      objToShow
    };
  }

  update() {
    const distanceToBottom =
      document.documentElement.offsetHeight -
      (window.pageYOffset + window.innerHeight);
    if (this.state.showingMore && distanceToBottom < 100) {
      this.setState({
        objToShow: this.state.objToShow + this.props.objToShow
      });
    }
    this.ticking = false;
  }

  handleScroll = () => {
    if (!this.ticking) {
      this.ticking = true;
      requestAnimationFrame(() => this.update());
    }
  };

  componentDidMount() {
    window.addEventListener(`scroll`, this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener(`scroll`, this.handleScroll);
    this.objToShow = this.state.objToShow;
  }

  render() {
    const Tile = this.props.tile;

    if (!this.props.list || this.props.list.edges.length <= 0) {
      return <span />;
    }

    const tiles = this.props.list.edges.map(e => e.node);
    let showLouder =
      tiles.length <= this.props.objToShow ? false : this.props.showmore;
    let objForCol = this.props.objForCol;
    const title = this.props.title;

    return (
      <div className={this.props.className}>
        <Title>{title}</Title>
        <Container col={`col${this.props.objForCol}`}>
          {chunk(
            tiles.slice(0, this.state.objToShow),
            this.props.objForCol
          ).map((chunk, indexRow) =>
            chunk.map((node, index) => {
              let coords = {
                x: [],
                y: []
              };
              let limit = 5;
              for (let i = 1; i < limit; i++) {
                coords.x[i] = ((index + indexRow * objForCol) % i) + 1;
                coords.y[i] =
                  Math.trunc((index + indexRow * objForCol) / i) + 1;
              }

              return (
                <Tile
                  key={node.id + '_' + index + '-' + indexRow}
                  data={node.tileInfo}
                  col={`col${this.props.objForCol}`}
                  x={coords.x}
                  y={coords.y}
                />
              );
            })
          )}
        </Container>

        {!this.state.showingMore && showLouder && (
          <LoaderContainer>
            <Loader
              data-testid="load-more"
              onClick={() => {
                this.setState({
                  objToShow: this.state.objToShow + this.props.objToShow,
                  showingMore: true
                });
              }}
            >
              <FormattedHTMLMessage id="loadMore" />
            </Loader>
          </LoaderContainer>
        )}
      </div>
    );
  }
}

export default injectIntl(Repeater);
