import React, { Component } from 'react';
import HamburgerMenu from 'react-hamburger-menu';
import Headroom from 'react-headroom';
import { animateScroll as scroll } from 'react-scroll';
import { injectIntl } from 'react-intl';

import {
  Container,
  LeftSide,
  RightSide,
  Logo,
  LogoImg,
  CompanyName,
  LogoLink,
  Nav,
  NavLink,
  ResponsiveMenuLink,
  ResponsiveMenu,
  ResponsiveMenuClose,
  WrapperHamburgerMenu,
  WrapperResponsiveMenu,
  GlobalStyle,
  NoScroll
} from './header-styled-component';

import WavelopLogo from '../../assets/images/wavelop-icon_white.svg';
import WavelopText from '../../assets/images/wavelop-text_white.svg';

interface HeaderProps {
  intl;
  disableLink?;
}

interface HeaderState {
  hamburgerMenu;
  items;
}

class Header extends Component<HeaderProps, HeaderState> {
  constructor(props) {
    super(props);
    this.state = {
      hamburgerMenu: {
        hidden: true
      },

      items: {
        it: [
          {
            title: 'headerServices',
            to: '/it/#servicesStrip'
          },
          {
            title: 'headerCoworking',
            to: '/it/coworking/'
          },
          {
            title: 'headerJoinUs',
            to: '/it/lavora-con-noi/'
          },
          {
            title: 'headerContacts',
            to: '/it/#formStrip'
          },
          {
            title: 'stories',
            to: '/it/story/'
          }
        ],
        en: [
          {
            title: 'headerServices',
            to: '/en/#servicesStrip'
          },
          {
            title: 'headerJoinUs',
            to: '/en/join-us/'
          },
          {
            title: 'headerContacts',
            to: '/en/#formStrip'
          },
          {
            title: 'stories',
            to: '/en/story/'
          }
          // {
          //   title: 'headerCoworking',
          //   to: '/en/coworking/'
          // }
        ]
      }
    };
  }

  show() {
    this.setState({ hamburgerMenu: { hidden: false } });
  }

  isHamburgerMenuOpen() {
    return this.state.hamburgerMenu.hidden;
  }

  handleClick() {
    this.setState({
      hamburgerMenu: {
        hidden: !this.state.hamburgerMenu.hidden
      }
    });
  }

  scrollToTop() {
    scroll.scrollToTop({
      duration: '500'
    });
  }

  render() {
    const { intl } = this.props;
    let menuItem = this.state.items[intl.locale];
    return (
      <Headroom>
        <GlobalStyle />
        {!this.state.hamburgerMenu.hidden ? <NoScroll /> : <span />}
        <Container id="headerContainer">
          <LeftSide>
            <Logo>
              <LogoLink to={`/${intl.locale}/`}>
                <LogoImg
                  src={WavelopLogo}
                  alt={intl.formatMessage({
                    id: 'headerLogoAlt'
                  })}
                />
                <CompanyName
                  src={WavelopText}
                  alt={intl.formatMessage({
                    id: 'headerLogoAlt'
                  })}
                />
              </LogoLink>
            </Logo>
          </LeftSide>
          <RightSide>
            <Nav>
              {!this.props.disableLink ? (
                <NavLink
                  // activeClass="active"
                  to={menuItem[0].to}
                  // spy="true"
                  // smooth="true"
                  offset={50}
                  duration={500}
                  style={{ color: 'white' }}
                >
                  {intl.formatMessage({
                    id: menuItem[0].title
                  })}
                </NavLink>
              ) : (
                <span />
              )}
              {!this.props.disableLink ? (
                <NavLink
                  // activeClass="active"
                  to={menuItem[1].to}
                  // spy="true"
                  // smooth="true"
                  offset={50}
                  duration={500}
                  style={{ color: 'white' }}
                >
                  {intl.formatMessage({
                    id: menuItem[1].title
                  })}
                </NavLink>
              ) : (
                <span />
              )}
              {!this.props.disableLink ? (
                <NavLink
                  // activeClass="active"
                  to={menuItem[2].to}
                  // spy="true"
                  // smooth="true"
                  offset={50}
                  duration={500}
                  style={{ color: 'white' }}
                >
                  {intl.formatMessage({
                    id: menuItem[2].title
                  })}
                </NavLink>
              ) : (
                <span />
              )}

              {!this.props.disableLink ? (
                <NavLink
                  // activeClass="active"
                  to={menuItem[3].to}
                  // spy="true"
                  // smooth="true"
                  offset={50}
                  duration={500}
                  style={{ color: 'white' }}
                >
                  {intl.formatMessage({
                    id: menuItem[3].title
                  })}
                </NavLink>
              ) : (
                <span />
              )}

              {!this.props.disableLink && menuItem[4] ? (
                <NavLink
                  // activeClass="active"
                  to={menuItem[4].to}
                  // spy="true"
                  // smooth="true"
                  offset={50}
                  duration={500}
                  style={{ color: 'white' }}
                >
                  {intl.formatMessage({
                    id: menuItem[4].title
                  })}
                </NavLink>
              ) : (
                <span />
              )}
            </Nav>
            {!this.props.disableLink ? (
              <WrapperHamburgerMenu>
                {this.hamburgerMenu('white', false)}
              </WrapperHamburgerMenu>
            ) : (
              <span />
            )}
            <WrapperResponsiveMenu>
              <ResponsiveMenu
                style={{
                  transform: this.state.hamburgerMenu.hidden
                    ? 'translate3d(100vw, 0, 0)'
                    : 'translate3d(0vw, 0, 0)',
                  height:
                    typeof window !== 'undefined' ? window.innerHeight : 0,
                  display: this.state.hamburgerMenu.hidden ? 'none' : 'flex'
                }}
              >
                {!this.props.disableLink ? (
                  <ResponsiveMenuClose>
                    {this.hamburgerMenu('black', true)}
                  </ResponsiveMenuClose>
                ) : (
                  <span />
                )}
                {!this.props.disableLink ? (
                  <ResponsiveMenuLink
                    // activeClass="active"
                    to={menuItem[0].to}
                    // spy="true"
                    // smooth="true"
                    offset={50}
                    duration={500}
                    delay={500}
                    onClick={() => {
                      this.handleClick();
                    }}
                    style={{
                      color: 'black',
                      padding: '10px'
                    }}
                  >
                    {intl.formatMessage({
                      id: menuItem[0].title
                    })}
                  </ResponsiveMenuLink>
                ) : (
                  <span />
                )}
                {!this.props.disableLink ? (
                  <ResponsiveMenuLink
                    // activeClass="active"
                    to={menuItem[1].to}
                    // spy="true"
                    // smooth="true"
                    offset={50}
                    duration={500}
                    delay={500}
                    onClick={() => {
                      this.handleClick();
                    }}
                    style={{
                      color: 'black',
                      padding: '10px'
                    }}
                  >
                    {intl.formatMessage({
                      id: menuItem[1].title
                    })}
                  </ResponsiveMenuLink>
                ) : (
                  <span />
                )}
                {!this.props.disableLink ? (
                  <ResponsiveMenuLink
                    // activeClass="active"
                    to={menuItem[2].to}
                    // spy="true"
                    // smooth="true"
                    offset={50}
                    duration={500}
                    delay={500}
                    onClick={() => {
                      this.handleClick();
                    }}
                    style={{
                      color: 'black',
                      padding: '10px'
                    }}
                  >
                    {intl.formatMessage({
                      id: menuItem[2].title
                    })}
                  </ResponsiveMenuLink>
                ) : (
                  <span />
                )}

                {!this.props.disableLink ? (
                  <ResponsiveMenuLink
                    // activeClass="active"
                    to={menuItem[3].to}
                    // spy="true"
                    // smooth="true"
                    offset={50}
                    duration={500}
                    delay={500}
                    onClick={() => {
                      this.handleClick();
                    }}
                    style={{
                      color: 'black',
                      padding: '10px'
                    }}
                  >
                    {intl.formatMessage({
                      id: menuItem[3].title
                    })}
                  </ResponsiveMenuLink>
                ) : (
                  <span />
                )}

                {!this.props.disableLink && menuItem[4] ? (
                  <ResponsiveMenuLink
                    // activeClass="active"
                    to={menuItem[4].to}
                    // spy="true"
                    // smooth="true"
                    offset={50}
                    duration={500}
                    delay={500}
                    onClick={() => {
                      this.handleClick();
                    }}
                    style={{
                      color: 'black',
                      padding: '10px'
                    }}
                  >
                    {intl.formatMessage({
                      id: menuItem[4].title
                    })}
                  </ResponsiveMenuLink>
                ) : (
                  <span />
                )}
              </ResponsiveMenu>
            </WrapperResponsiveMenu>
          </RightSide>
        </Container>
      </Headroom>
    );
  }

  hamburgerMenu(color: string, status: boolean) {
    return (
      <HamburgerMenu
        isOpen={status}
        menuClicked={this.handleClick.bind(this)}
        width={30}
        height={20}
        strokeWidth={3}
        rotate={0}
        color={color}
        borderRadius={0}
        animationDuration={0.5}
      />
    );
  }
}

export default injectIntl(Header);
