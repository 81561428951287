import React, { Component } from 'react';
import { Element } from 'react-scroll';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';

// Component
import {
  Strip
  // Form
} from '..';

// Style
import {
  Container,
  Title,
  SubTitle,
  // FormContainer,
  Iframe
} from './findUsStrip-styled-component';
import { theme } from '../../theme/theme';

interface FindUsStripProps {
  intl;
}

class FindUsStrip extends Component<FindUsStripProps> {
  constructor(props) {
    super(props);
  }

  render() {
    const { intl } = this.props;
    return (
      <Element name="findUsStrip" id="findUsStrip" className="element">
        <Strip
          overrideStyle={`
        background-color: ${theme.lightWhite};
        `}
        >
          <Container>
            <Title>
              {intl.formatMessage({
                id: 'findUsStripTitle'
              })}
            </Title>
            <SubTitle>
              <FormattedHTMLMessage id="findUsStripSubtitle" />
            </SubTitle>

            <Iframe
              {...{ loading: 'lazy', allowfullscreen: true }}
              src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDJBmaVHtDg41zOUNgQ9fGjFBldpvVTTUg&q=Wavelop+-+Soluzioni+digitali+per+il+tuo+business"
            ></Iframe>
          </Container>
        </Strip>
      </Element>
    );
  }
}
export default injectIntl(FindUsStrip);
