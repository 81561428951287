import React from 'react';
import { Container } from './titleCover-styled-component';

const TileCover = props => {
  const { children } = props;

  return <Container>{children}</Container>;
};

export default TileCover;
