import styled, { css } from 'styled-components';
import { theme } from '../../theme/theme';

export const H4 = styled.h4`
  font-size: 1.325rem;
  font-weight: 500;
  margin: 0.5em 0;

  @media (min-width: ${theme.sizes.phone}px) {
    font-size: 1.5rem;
  }
`;
