import React, { Component } from 'react';
import nprogress from 'nprogress';
import browserLang from 'browser-lang';

import HomePage from '../components/HomePage';

export default class IndexPage extends Component {
	constructor(props) {
		super(props);
		const { pageContext } = props;

		if (typeof document !== 'undefined') {
			nprogress.start();
		}

		if (typeof window !== 'undefined') {
			const myLanguage = browserLang({
				languages: ['it', 'en'],
				fallback: 'en',
			});

			window.___replace(
				`/${
					Object.keys(pageContext).length
						? pageContext.language
						: myLanguage
				}/`,
			);
		}
	}
	render() {
		return <HomePage id="container" {...this.props} />;
	}
}
