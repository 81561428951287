import React from 'react';

import {
  Container,
  InfoContainer,
  Title,
  Summary,
  Date,
  CoverImage,
  TileLink
} from './storytile-styled-component';

const StoryTile = props => {
  const { data, cover, col, x, y } = props;
  const img =
    typeof window !== 'undefined'
      ? window.location.origin + data.meta.coverImage
      : '';
  return (
    <Container cover={cover} col={col} x={x} y={y}>
      <TileLink to={data.path}>
        <CoverImage
          className={col}
          fluid={data.meta.coverImage.childImageSharp.fluid}
        />
        <InfoContainer className={cover ? '' : 'text--scale-fs'} cover={cover}>
          <Title>{data.meta.title}</Title>
          <Date>{data.meta.date}</Date>
          <Summary>{data.meta.summary}</Summary>
        </InfoContainer>
      </TileLink>
    </Container>
  );
};

export default StoryTile;
