import * as styledComponents from 'styled-components';

const {
  default: styled,
  createGlobalStyle
} = styledComponents as styledComponents.ThemedStyledComponentsModule<
  IThemeInterface
>;

export interface IThemeInterface {
  primaryColor: string;
  deepBlue: string;
  regolarWhite: string;
  black75: string;
  aqua: string;
  lightBlack: string;
  lightWhite: string;
  gray: string;
  grayDarker: string;
  wavelopBlue: string;
  sizes: {
    full: number;
    desktop: number;
    tablet: number;
    phone: number;
  };
}

export const theme = {
  primaryColor: '#e9e9eb',
  deepBlue: '#2b337c',
  regolarWhite: '#ffffff',
  black75: 'rgba(0, 0, 0, 0.75)',
  aqua: '#5cc9ce',
  lightBlack: '#212121',
  lightWhite: '#F5F5F5',
  gray: 'rgb(138, 138, 138)',
  grayDarker: '#595959',
  wavelopBlue: '#3b5a9c',
  sizes: { full: 1200, desktop: 992, tablet: 768, phone: 576 }
};

export const GlobalStyle = createGlobalStyle`
    html,
	body {
        margin: 0px;
        padding: 0px;
        font-family: 'Roboto', sans-serif;
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
		color: ${theme.lightBlack};
		font-weight: 300;
        overflow-x: hidden;
        max-width: 100vw;
	}
	
	strong, b {
		font-weight: bolder;
	}
`;

export default styled;
