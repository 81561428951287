import React, { Component } from 'react';
import { graphql } from 'gatsby';
import RehypeReact from 'rehype-react';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

import withIntl from '../../i18n/withIntl';

import {
  Layout,
  Strip,
  Headline1,
  Headline2,
  Headline3,
  Paragraph,
  StoryTile,
  EventTile,
  Repeater
} from '../../components';

import { GlobalStyle } from './stories-styled-component';
import { theme } from '../../theme/theme';

interface PageProps {
  pageContext;
  data;
}

const renderAst = new RehypeReact({
  createElement: React.createElement,
  components: {
    h1: Headline1,
    h2: Headline2,
    h3: Headline3,
    p: Paragraph
  }
}).Compiler;

class Stories extends Component<PageProps, {}> {
  constructor(props) {
    super(props);
  }

  orderStories = originalStories => {
    originalStories.sort((a, b) => {
      const date1 = new Date(a.node.tileInfo.meta.date);
      const date2 = new Date(b.node.tileInfo.meta.date);
      return date1 < date2 ? 1 : date2 < date1 ? -1 : 0;
    });

    return { edges: originalStories };
  };

  public render() {
    const {
      markdownRemark,
      stories: originalStories,
      events
    } = this.props.data;

    if (originalStories.edges.length <= 0) {
      return;
    }
    const stories =
      originalStories &&
      originalStories.edges &&
      originalStories.edges.length > 0
        ? this.orderStories(originalStories.edges)
        : { edges: [] };

    if (events && events.edges.length > 0) {
      let filterEvents = _.filter(events.edges, obj => {
        let ds = obj.node.tileInfo.meta.compareDate;
        let date = new Date(ds);
        var q = new Date();
        var m = q.getMonth();
        var d = q.getDate();
        var y = q.getFullYear();
        var today = new Date(y, m, d);

        return date >= today;
      });
      let sorted = _.sortBy(filterEvents, [
        o => {
          return o.node.tileInfo.meta.compareDate;
        }
      ]);
      events.edges = sorted;
    }

    let coverData = stories.edges.slice(0, 1)[0].node.tileInfo;

    // if (stories) {
    // 	for (var i = 0; i < 50; i++) {
    // 		stories.edges.push(stories.edges[0]);
    // 	}
    // }

    // if (events) {
    // 	for (var i = 0; i < 10; i++) {
    // 		events.edges.push(events.edges[0]);
    // 	}
    // }

    let topStories = {
      edges: stories.edges.slice(1, 3)
    };

    let otherStories = {
      edges: stories.edges.slice(3)
    };

    return (
      <Layout {...this.props}>
        <GlobalStyle />
        <Strip>{renderAst(markdownRemark.htmlAst)}</Strip>
        <Strip
          overrideStyle={`
                        display: flex;
						padding-bottom: 6em;
						background: ${theme.lightWhite};
						@media (max-width: ${theme.sizes.phone}px) {        
							padding-top: 2.5rem;
						}
					`}
        >
          <StoryTile data={coverData} cover={true} />
          {topStories && topStories.edges.length > 0 && (
            <Repeater
              list={topStories}
              objToShow={2}
              objForCol={2}
              tile={StoryTile}
              showmore={false}
            />
          )}
          {events && events.edges.length > 0 && (
            <Strip
              overrideStyle={`
						margin-bottom: 2em;
						@media (min-width: ${theme.sizes.phone}px) {
							margin: 2em 0 3em 0;
						}
					`}
            >
              <Repeater
                title={<FormattedMessage id="nextEvents" />}
                list={events}
                objToShow={2}
                objForCol={2}
                tile={EventTile}
                showmore={false}
              />
            </Strip>
          )}
          {otherStories && otherStories.edges.length > 0 && (
            <Repeater
              list={otherStories}
              objToShow={12}
              objForCol={3}
              tile={StoryTile}
              showmore={true}
            />
          )}
        </Strip>
      </Layout>
    );
  }
}

export default withIntl(Stories);

export const pageQuery = graphql`
  query($path: String!, $language: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      htmlAst
    }

    stories: allMarkdownRemark(
      filter: {
        frontmatter: { view: { eq: "story" }, language: { eq: $language } }
      }
    ) {
      edges {
        node {
          id
          ...StoryTileFragment
        }
      }
    }

    events: allMarkdownRemark(
      filter: {
        frontmatter: { view: { eq: "event" }, language: { eq: $language } }
      }
    ) {
      edges {
        node {
          id
          ...EventTileFragment
        }
      }
    }
  }
`;
