import React, { Component } from 'react';
import { graphql } from 'gatsby';
import RehypeReact from 'rehype-react';

import withIntl from '../../i18n/withIntl';

import {
  Layout,
  Strip,
  Headline1,
  Headline2,
  Headline3,
  Headline4,
  Paragraph,
  List
} from '../../components';

import { GlobalStyle } from './page-styled-component';

interface PageProps {
  pageContext;
  data;
}

const renderAst = new RehypeReact({
  createElement: React.createElement,
  components: {
    h1: Headline1,
    h2: Headline2,
    h3: Headline3,
    h4: Headline4,
    p: Paragraph,
    ul: List
  }
}).Compiler;

class Page extends Component<PageProps, {}> {
  constructor(props) {
    super(props);

    // console.log(this.props);
  }

  public render() {
    const { markdownRemark } = this.props.data;

    return (
      <Layout {...this.props}>
        <GlobalStyle />
        <Strip
          overrideStyle={`
                        display: flex;
                        margin-bottom: 6em;
					`}
        >
          {renderAst(markdownRemark.htmlAst)}
        </Strip>
      </Layout>
    );
  }
}

export default withIntl(Page);

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      htmlAst
    }
  }
`;
