import styled, { theme } from '../../theme/theme';

export const Nav = styled.a`
  color: ${theme.deepBlue};
  text-decoration: none;
  margin-bottom: 0.75rem;
  font-weight: 300;
  font-size: 1.1rem;
  &:hover {
    text-decoration: underline;
  }
`;

export const Paragraph = styled.p`
  margin-bottom: 0.75rem;
  font-weight: 300;
  font-size: 1.1rem;
  margin: 0 0 2rem 0;
`;

export const ParagraphWithStyle = styled(Paragraph)`
  a {
    color: ${theme.deepBlue};
    text-decoration: none;
    margin-bottom: 0.75rem;
    font-weight: 300;
    font-size: 1.1rem;
    &:hover {
      text-decoration: underline;
    }
  }
`;
