import React from 'react';
import { FormComponentGroup } from '../formComponents-styled-component';
import { SubmitComponent } from './button-styled-component';

const Button = props => {
  return (
    <FormComponentGroup block>
      <SubmitComponent
        id={props.name}
        type={props.inputtype}
        value={props.value}
        onSubmit={props.onsubmit}
        {...props}
      />
    </FormComponentGroup>
  );
};

export default Button;
