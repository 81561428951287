import styled, { theme } from '../../theme/theme';

export const Container = styled.div`
  display: flex;
  justify-content: end;
  flex-flow: column;
  color: ${theme.regolarWhite};

  h1 {
    text-align: left;
  }

  @media (max-width: ${theme.sizes.tablet}px) {
    height: auto;
  }
`;
