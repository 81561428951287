import React, { Component } from 'react';
import { Element } from 'react-scroll';
import { Link } from 'react-scroll';
import LazyLoad from 'react-lazyload';
import { injectIntl } from 'react-intl';

import { Strip } from '../';

import {
  Container,
  LeftSide,
  Title,
  Description,
  ContactUsButton,
  RightSide,
  Image
} from './coverStrip-styled-component';

import PeopleImage from '../../assets/images/1.svg';

interface StripProps {
  intl;
}

class CoverStrip extends Component<StripProps> {
  constructor(props) {
    super(props);
  }

  render() {
    const { intl } = this.props;

    return (
      <Element name="coverStrip" className="element">
        <Strip
          overrideStyle={`
                margin-top: -80px;
                background: linear-gradient(0.25turn, #2b337c, #5cc9ce);
                `}
        >
          <Container>
            <LeftSide>
              <Title>
                {intl.formatMessage({
                  id: 'coverStripTitle'
                })}
              </Title>
              <Description>
                {intl.formatMessage({
                  id: 'coverStripDescription'
                })}
              </Description>
              <ContactUsButton>
                <Link
                  activeClass="active"
                  to="formStrip"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                >
                  {intl.formatMessage({
                    id: 'coverStripContactUs'
                  })}
                </Link>
              </ContactUsButton>
            </LeftSide>
            <RightSide>
              <LazyLoad height={200} once>
                <Image
                  alt={intl.formatMessage({
                    id: 'coverStripTitle'
                  })}
                  src={PeopleImage}
                />
              </LazyLoad>
            </RightSide>
          </Container>
        </Strip>
      </Element>
    );
  }
}

export default injectIntl(CoverStrip);
