import React, { Component } from 'react';
import Layout from '../../components/Layout';
import Strip from '../../components/Strip';
import withIntl from '../../i18n/withIntl';
import { FormattedMessage } from 'react-intl';
import { Link } from 'gatsby';

import {
  Container,
  h1 as H1,
  p as P,
  GlobalStyle
} from './404-styled-component';

interface NotFoundPageProps {
  pageContext;
}

class NotFoundPage extends Component<NotFoundPageProps, {}> {
  constructor(props) {
    super(props);
  }
  public render() {
    return (
      <Layout disableLink={true} {...this.props}>
        <Strip>
          <GlobalStyle />
          <Container>
            <H1>
              <FormattedMessage id="notFoundPageTitle" />
            </H1>
            <P>
              <FormattedMessage id="notFoundPageDescription" />
            </P>
            <Link to="/">
              {' '}
              <FormattedMessage id="notFoundPageGoToHome" />
            </Link>
          </Container>
        </Strip>
      </Layout>
    );
  }
}

export default withIntl(NotFoundPage);
