import styled, { css } from 'styled-components';

export const FormComponentGroup = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  ${props =>
    props.block &&
    css`
      display: block;
    `};
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 0.75rem;
  font-size: 1.375rem;
  font-weight: 300;

  ${props =>
    props.checkboxInline &&
    css`
      font-size: 1.1rem;
      margin-right: 0.5rem;
      display: block;
      position: relative;
      padding-left: 28px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    `};
`;
