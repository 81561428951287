import React from 'react';
import { FormComponentGroup, Label } from '../formComponents-styled-component';
import {
  CheckBoxContainer,
  CheckBoxComponent
} from './checkBox-styled-component';

const CheckBox = props => {
  return (
    <FormComponentGroup>
      <Label htmlFor={props.name}>{props.title}</Label>
      <CheckBoxContainer>
        {props.options.map(option => {
          return (
            <Label checkboxInline key={option.value}>
              <CheckBoxComponent
                name={props.name}
                onChange={props.handlechange}
                value={option.value}
                checked={props.selectedOptions.indexOf(option.value) > -1}
                type="checkbox"
              />
              {option.label}
            </Label>
          );
        })}
      </CheckBoxContainer>
    </FormComponentGroup>
  );
};

export default CheckBox;
