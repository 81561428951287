import React, { Component } from 'react';
import nprogress from 'nprogress';
import browserLang from 'browser-lang';

import Layout from '../components/Layout';

export default class NotFoundPage extends Component {
  constructor(props) {
    super(props);
    const { pageContext } = props;

    if (typeof document !== 'undefined') {
      nprogress.start();
    }

    if (typeof window !== 'undefined') {
      const myLanguage = browserLang({
        languages: ['it', 'en'],
        fallback: 'en'
      });

      window.___replace(
        `/${
          Object.keys(pageContext).length &&
          pageContext.hasOwnProperty('language')
            ? pageContext.language
            : myLanguage
        }/404/`
      );
    }
  }

  render() {
    return <Layout {...this.props} />;
  }
}
