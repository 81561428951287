import React, { Component } from 'react';
import { graphql } from 'gatsby';
import RehypeReact from 'rehype-react';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

import withIntl from '../../i18n/withIntl';

import {
  Layout,
  Strip,
  Headline1,
  Headline2,
  Headline3,
  Paragraph,
  Repeater,
  TitleCover,
  JobTile,
  OpenWorksStrip
} from '../../components';

import { GlobalStyle } from './joinus-styled-component';
import { theme } from '../../theme/theme';

interface PageProps {
  pageContext;
  data;
}

const renderAst = new RehypeReact({
  createElement: React.createElement,
  components: {
    h1: Headline1,
    h2: Headline2,
    h3: Headline3,
    p: Paragraph,
    titlecover: TitleCover
  }
}).Compiler;

class JoinUs extends Component<PageProps, {}> {
  constructor(props) {
    super(props);
  }

  orderJob = job => {
    job.sort((a, b) => {
      const date1 = new Date(a.node.tileInfo.date);
      const date2 = new Date(b.node.tileInfo.date);
      return date1 < date2 ? 1 : date2 < date1 ? -1 : 0;
    });

    return { edges: job };
  };

  public render() {
    const { markdownRemark, jobs } = this.props.data;

    const jobsActive =
      jobs &&
      jobs.edges.length > 0 &&
      jobs.edges.filter((job: any) => job.node.tileInfo.validity);
    const jobsNotActive =
      jobs &&
      jobs.edges.length > 0 &&
      jobs.edges.filter((job: any) => !job.node.tileInfo.validity);

    return (
      <Layout {...this.props}>
        <GlobalStyle />
        <Strip
          overrideStyle={`
              background: linear-gradient(0.25turn, #2b337c, #5cc9ce);
              color: ${theme.regolarWhite};
          `}
        >
          {renderAst(markdownRemark.htmlAst)}
        </Strip>

        <OpenWorksStrip jobs={jobsActive}></OpenWorksStrip>

        <Strip
          overrideStyle={`
              display: flex;
              padding-bottom: 6em;
              background: ${theme.lightWhite};
              @media (max-width: ${theme.sizes.phone}px) {        
                padding-top: 2.5rem;
              }
					`}
        >
          {jobs && jobsNotActive.length > 0 && (
            <Repeater
              title={<FormattedMessage id="noMoreLookingFor" />}
              list={this.orderJob(jobsNotActive)}
              objToShow={12}
              objForCol={2}
              tile={JobTile}
              showmore={false}
            />
          )}
        </Strip>
      </Layout>
    );
  }
}

export default withIntl(JoinUs);

export const pageQuery = graphql`
  query($path: String!, $language: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      htmlAst
    }

    jobs: allMarkdownRemark(
      filter: {
        frontmatter: { view: { eq: "job" }, language: { eq: $language } }
      }
    ) {
      edges {
        node {
          id
          ...JobTileFragment
        }
      }
    }
  }
`;
