import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

import {
  Container,
  Title,
  ImageContainer,
  InfoContainer,
  Icon,
  Summary
} from './methodTile-styled-component';

const MethodTile = props => {
  return (
    <Container>
      <ImageContainer>
        <Icon src={props.icon} alt={props.alt} />
      </ImageContainer>
      <InfoContainer>
        <Title>
          <FormattedHTMLMessage id={props.title} />
        </Title>
        <Summary>
          <FormattedHTMLMessage id={props.summary} />
        </Summary>
      </InfoContainer>
    </Container>
  );
};
export default MethodTile;
