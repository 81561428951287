import { Link } from 'react-scroll';
import { createGlobalStyle } from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';
import styled, { theme } from '../../theme/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  padding: 0px;
  max-width: ${theme.sizes.full}px;
  margin: 0 auto;
  height: 80px;
  max-height: 80px;
  @media (max-width: ${theme.sizes.full}px) {
    padding: 0px 20px;
  }
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const Logo = styled.div`
  font-size: 1.5em;
  text-align: center;
  color: palevioletred;
`;

export const LogoImg = styled.img`
  width: 100px;
  margin-left: -17px;
`;

export const LogoLink = styled(GatsbyLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

export const CompanyName = styled.img`
  width: 140px;
  margin-left: 10px;
`;

export const Nav = styled.div`
  font-size: 1.3rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  color: white;
  display: none;
  @media (min-width: ${theme.sizes.desktop + 20}px) {
    display: block;
  }
`;

export const NavLink = styled(GatsbyLink)`
  margin: 0px 0px 0px 40px;
  text-decoration: none;
  cursor: pointer;
`;

export const WrapperHamburgerMenu = styled.div`
  display: block;
  cursor: pointer;
  @media (min-width: ${theme.sizes.desktop + 20}px) {
    display: none;
  }
`;

export const WrapperResponsiveMenu = styled.div`
  display: block;
  @media (min-width: ${theme.sizes.desktop + 20}px) {
    display: none;
  }
`;

export const ResponsiveMenu = styled.div`
  width: 100vw;
  min-height: 100vh;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
  z-index: 1000;
  transform: translate3d(100vw, 0, 0);
  display: flex;
  flex-direction: column;

  @media (min-width: ${theme.sizes.desktop + 20}px) {
    display: none;
  }
`;

export const ResponsiveMenuLink = styled(GatsbyLink)`
  margin: 0px 20px;
  text-decoration: none;
  font-size: 2rem;
  cursor: pointer;
`;

export const ResponsiveMenuClose = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 20px;
  cursor: pointer;
`;

export const GlobalStyle = createGlobalStyle`
    .headroom {
        transition: background 1000ms easy-in;
        box-shadow: none;
	}
    .headroom--unpinned {
        transition: none !important; /* https://github.com/KyleAMathews/react-headroom/issues/154 */
    }
    .headroom.headroom--pinned.headroom--scrolled {
        background: linear-gradient(0.25turn, ${theme.deepBlue}, ${theme.aqua});
        -webkit-box-shadow: 0px 1px 5px -1px ${theme.black75};
        -moz-box-shadow: 0px 1px 5px -1px ${theme.black75};
        box-shadow: 0px 1px 5px -1px ${theme.black75};
    }
`;

export const NoScroll = createGlobalStyle`
    html {
		overflow: hidden;
		height: auto !important;
	}
	.headroom-wrapper {
		height: 0px !important;
	}
`;
