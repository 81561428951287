import React from 'react';
import { FormComponentGroup, Label } from '../formComponents-styled-component';
import { TextAreaComponent } from './textArea-styled-component';

const TextArea = props => (
  <FormComponentGroup>
    <Label htmlFor={props.name}>{props.title}</Label>
    <TextAreaComponent
      id={props.name}
      name={props.name}
      rows={props.rows}
      cols={props.cols}
      value={props.value}
      onChange={props.handlechange}
      placeholder={props.placeholder}
    />
  </FormComponentGroup>
);

export default TextArea;
