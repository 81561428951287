import styled, { theme } from '../../theme/theme';

export const Container = styled.div`
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 100%;
  grid-template-columns: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 0 50px 0;

  // IE11 Workaround for grid layout

  .title {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
  }

  .description {
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
  }

  .repeaterTile {
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
  }
`;

export const TitleH2 = styled.h3`
  grid-row-end: span 1;
  grid-column-end: span 2;
  text-align: center;
  font-size: 2rem;
  font-weight: 500;
  padding: 0;
  margin-top: 0px;
  color: ${theme.regolarWhite};
  @media (max-width: ${theme.sizes.phone}px) {
    grid-column-end: span 1;
    text-align: left;
  }
  &.mainTitle {
    font-size: 3.5rem;
  }
`;

export const LongDescription = styled.p`
  grid-row-end: span 1;
  grid-column-end: span 2;
  text-align: center;
  width: 70%;
  margin: 0 auto;
  font-size: 1.375rem;
  padding: 0 0 50px 0;
  color: ${theme.regolarWhite};
  @media (max-width: ${theme.sizes.phone}px) {
    grid-column-end: span 1;
    width: 100%;
    text-align: left;
  }
`;
