import styled from 'styled-components';
import { theme } from '../../theme/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding-bottom: 4rem;
  align-items: center;
`;

export const Title = styled.h2`
  text-align: center;
  grid-row-end: span 1;
  grid-column-end: span 2;
  text-align: center;
  font-size: 3rem;
  font-weight: 500;
  padding: 0;
  @media (max-width: ${theme.sizes.phone}px) {
    grid-column-end: span 1;
    text-align: left;
  }
  @media (max-width: ${theme.sizes.tablet}px) {
    width: 100%;
  }
`;

export const Iframe = styled.iframe`
  width: 100%;
  height: 450px;
  border: 0;
`;

export const SubTitle = styled.p`
  font-weight: 300;
  font-size: 1.375rem;
  padding-bottom: 1rem;
  @media (max-width: ${theme.sizes.tablet}px) {
    text-align: left;
  }

  a {
    color: ${theme.deepBlue};
    text-decoration: none;
    margin-bottom: 0.75rem;
    font-weight: 300;
    &:hover {
      text-decoration: underline;
    }
  }
`;
