import React, { Component } from 'react';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';
import { OpenWorksStrip, Link } from '..';
import {
  Description,
  TitleH2
} from './openWorksHomePageStrip-styled-component';
import { theme } from '../../theme/theme';

interface StripProps {
  intl;
  jobs;
  name;
}

class OpenWorksHomePageStrip extends Component<StripProps> {
  constructor(props) {
    super(props);
  }

  render() {
    const { intl, jobs, name } = this.props;

    return (
      <>
        <OpenWorksStrip
          overrideStyle={`
            background-color: ${theme.lightWhite};
            padding-top: 2rem;
          `}
          name={name}
          jobs={jobs}
          max={2}
        >
          <TitleH2 className={`title`}>
            <FormattedHTMLMessage id="openWorksHomePageTitle" />
          </TitleH2>
          <Description className={`description`}>
            <FormattedHTMLMessage id="openWorksHomePageDescription" />
          </Description>
        </OpenWorksStrip>

        <Description className={`endDescription`}>
          <FormattedHTMLMessage id="openWorksHomePageEndDescriptionStart" />
          <Link
            activeClass="active"
            to={intl.formatMessage({
              id: 'openWorksHomePageEndDescriptionLink'
            })}
            spy={true}
            smooth={true}
            offset={50}
            duration={500}
          >
            {intl.formatMessage({
              id: 'openWorksHomePageEndDescriptionLinkText'
            })}
          </Link>
        </Description>
      </>
    );
  }
}

export default injectIntl(OpenWorksHomePageStrip);
