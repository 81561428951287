import React, { Component } from 'react';
import { Element } from 'react-scroll';
import LazyLoad from 'react-lazyload';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';

import { Strip } from '../';

import {
  Container,
  TitleH2,
  LongDescription,
  LongImage,
  LeftText,
  RightText,
  LeftImage,
  RightImage,
  TitleH3
} from './servicesStrip-styled-component';
import { theme } from '../../theme/theme';

import whatWeDoImage from '../../assets/images/2.svg';
import digitalProductDesignImage from '../../assets/images/3.svg';
import frontEndImage from '../../assets/images/4.svg';
import backEndImage from '../../assets/images/5.svg';
import hybridMobileDevelopmentImage from '../../assets/images/6.svg';
import chatBotEndImage from '../../assets/images/7.svg';
import awsImage from '../../assets/images/14.svg';

interface StripProps {
  intl;
}

class ServicesStrip extends Component<StripProps> {
  constructor(props) {
    super(props);
  }

  render() {
    const { intl } = this.props;

    return (
      <Element name="servicesStrip" id="servicesStrip" className="element">
        <Strip
          overrideStyle={`
                background-color: ${theme.lightWhite};
                `}
        >
          <Container>
            <TitleH2 className={`mainTitle`}>
              <FormattedHTMLMessage id="servicesStripWhatWeDoTitle" />
            </TitleH2>
            <LongDescription className={`whatWeDoDescription`}>
              <FormattedHTMLMessage id="servicesStripWhatWeDoDescription" />
            </LongDescription>
            <TitleH3 className={'whatWeDoSlogan'}>
              {intl.formatMessage({
                id: 'servicesStripWhatWeDoSlogan'
              })}
            </TitleH3>
            <LazyLoad height={300} offset={1000} once>
              <LongImage
                className={`whatWeDoImage`}
                src={whatWeDoImage}
                alt={intl.formatMessage({
                  id: 'servicesStripWhatWeDoAlt'
                })}
              />
            </LazyLoad>

            {/* Digital product - section */}
            <TitleH2 className={`digitalProductDesignTitle`}>
              {intl.formatMessage({
                id: 'servicesStripDigitalProductDesignTitle'
              })}
            </TitleH2>
            <LazyLoad height={300} offset={1000} once>
              <LeftImage
                className={`digitalProductDesignImage`}
                src={digitalProductDesignImage}
                alt={intl.formatMessage({
                  id: 'servicesStripDigitalProductDesignTitle'
                })}
              />
            </LazyLoad>
            <RightText className={`digitalProductDesignDescription`}>
              <FormattedHTMLMessage id="servicesStripDigitalProductDesignDescription" />
            </RightText>
            {/* ----- */}

            {/* Web development - section */}
            <TitleH2 className={`webDevelopmentTitle`}>
              {intl.formatMessage({
                id: 'servicesStripWebDevelopmentTitle'
              })}
            </TitleH2>
            <LazyLoad height={300} offset={1000} once>
              <LeftImage
                className={'frontEndImage'}
                src={frontEndImage}
                alt={intl.formatMessage({
                  id: 'servicesStripFrontEndAlt'
                })}
              />
            </LazyLoad>
            <LazyLoad height={300} offset={1000} once>
              <RightImage
                className={'backEndImage'}
                src={backEndImage}
                alt={intl.formatMessage({
                  id: 'servicesStripBackEndAlt'
                })}
              />
            </LazyLoad>
            <TitleH3 className={'frontEndTitle'}>
              {intl.formatMessage({
                id: 'servicesStripFrontEndTitle'
              })}
            </TitleH3>
            <TitleH3 className={'backEndTitle'}>
              {intl.formatMessage({
                id: 'servicesStripBackEndTitle'
              })}
            </TitleH3>
            <LeftText className={'frontEndDescription'}>
              <FormattedHTMLMessage id="servicesStripFrontEndDescription" />
            </LeftText>
            <RightText className={'backEndDescription'}>
              <FormattedHTMLMessage id="servicesStripBackEndDescription" />
            </RightText>
            {/* ----- */}

            {/* AWS - section */}
            <TitleH2 className={`awsTitle`}>
              {intl.formatMessage({
                id: 'awsTitle'
              })}
            </TitleH2>
            <LeftText className={`awsDescription`}>
              <FormattedHTMLMessage id="awsDescription" />
            </LeftText>
            <LazyLoad height={300} offset={1000} once>
              <RightImage
                className={`awsImage`}
                src={awsImage}
                alt={intl.formatMessage({
                  id: 'servicesStripAwsAlt'
                })}
              />
            </LazyLoad>
            {/* ----- */}

            {/* Other - section */}
            <TitleH2 className={`otherServicesTitle`}>
              {intl.formatMessage({
                id: 'servicesStripOtherServicesTitle'
              })}
            </TitleH2>
            <LazyLoad height={300} offset={1000} once>
              <LeftImage
                className={'hybridMobileDevelopmentImage'}
                src={hybridMobileDevelopmentImage}
                alt={intl.formatMessage({
                  id: 'servicesStripFrontEndAlt'
                })}
              />
            </LazyLoad>
            <LazyLoad height={300} offset={1000} once>
              <RightImage
                className={'chatBotEndImage'}
                src={chatBotEndImage}
                alt={intl.formatMessage({
                  id: 'servicesStripChatBotEndAlt'
                })}
              />
            </LazyLoad>
            <TitleH3 className={'hybridMobileDevelopmentTitle'}>
              {intl.formatMessage({
                id: 'servicesStripHybridMobileDevelopmentTitle'
              })}
            </TitleH3>
            <TitleH3 className={'chatBotTitle'}>
              {intl.formatMessage({
                id: 'servicesStripChatBotEndTitle'
              })}
            </TitleH3>
            <LeftText className={'hybridMobileDevelopmentDescription'}>
              <FormattedHTMLMessage id="servicesStripHybridMobileDevelopmentDescription" />
            </LeftText>
            <RightText className={'chatBotDescription'}>
              <FormattedHTMLMessage id="servicesStripChatBotDescription" />
            </RightText>
            {/* ----- */}
          </Container>
        </Strip>
      </Element>
    );
  }
}

export default injectIntl(ServicesStrip);
