import styled, { css } from 'styled-components';
import { theme } from '../../theme/theme';
import { H2 } from '../Headline2/headline2-styled-component';

export const Container = styled.div`
  list-style-type: none;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 100%;
  grid-template-columns: 100%;
  margin: 0;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;

  @media (min-width: ${theme.sizes.phone}px) {
    -ms-grid-columns: 49% 49%;
    grid-template-columns: 49% 49%;
  }

  @media (min-width: ${theme.sizes.desktop}px) {
    ${props =>
      props.col &&
      props.col == 'col3' &&
      css`
        -ms-grid-columns: 32% 32% 32%;
        grid-template-columns: 32% 32% 32%;
      `};

    ${props =>
      props.col &&
      props.col == 'col4' &&
      css`
        -ms-grid-columns: 24% 24% 24% 24%;
        grid-template-columns: 24% 24% 24% 24%;
      `};
  }
`;

export const LoaderContainer = styled.div`
  box-sizing: border-box;
  width: 100;
  display: flex;
  margin: 3rem auto;
  justify-content: center;
`;

export const Loader = styled.a`
  cursor: pointer;
  font-size: 1.25rem;
  color: ${theme.wavelopBlue};
  padding-bottom: 2px;
  border-bottom: 1px solid ${theme.wavelopBlue};
`;

export const Title = styled(H2)`
  margin: 1.5em 0;
  @media (min-width: ${theme.sizes.phone}px) {
    font-size: 2.275rem;
  }
`;
