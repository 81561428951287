import styled from '../../theme/theme';

export const MainLayout = styled.div`
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const Main = styled.div`
  flex: 1;

  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    /* IE10+ specific styles go here */

    flex: auto; // O il giusto valore per rimuovore "flex: 1"e
  }
`;
