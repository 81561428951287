import { Link as GatsbyLink } from 'gatsby';
import styled, { theme } from '../../theme/theme';

export const Container = styled.div`
  margin: 0.5rem 0;
`;

export const Separator = styled.span`
  margin: 0 0.25rem;
`;
