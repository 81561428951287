import { createGlobalStyle } from 'styled-components';
import styled, { theme } from '../../theme/theme';
import { H1 } from '../../components/Headline1/headline1-styled-component';
import { H3 } from '../../components/Headline3/headline3-styled-component';
import { P } from '../../components/Paragraph/paragraph-styled-component';

export const GlobalStyle = createGlobalStyle`
  html,
	body {
		position: relative;
		min-height: 100vh;
	}
	
	#___gatsby {
		position: relative;
		min-height: 100vh;
	}

	/**
	 * https://github.com/gatsbyjs/gatsby/issues/7310
	**/

	#___gatsby > div {
		position: relative;
		min-height: 100vh;
	}

  .headroom {
    transition: background 1000ms easy-in;
    box-shadow: none;
    background: linear-gradient(0.25turn, ${theme.deepBlue}, ${theme.aqua});
  }

  .headroom--unpinned {
    transition: none !important; /* https://github.com/KyleAMathews/react-headroom/issues/154 */
  }

  .headroom.headroom--pinned.headroom--scrolled {
    -webkit-box-shadow: 0px 1px 5px -1px ${theme.black75};
    -moz-box-shadow: 0px 1px 5px -1px ${theme.black75};
    box-shadow: 0px 1px 5px -1px ${theme.black75};
  }
  
  /* Workaround written too late in the night -> to fix */
  .gatsby-resp-image-link {
    margin: auto 0 !important;
    width: 100% !important;
  }
`;

export const Container = styled.div`
  background-color: ${theme.regolarWhite};
`;

export const BlogStrip = styled.div`
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 6rem;

  @media (min-width: ${theme.sizes.desktop}px) {
    & .gatsby-resp-image-link {
      margin: 2rem -200px;
      width: auto;
      height: auto;
    }
  }
`;
export const Title = styled(H1)`
  padding: 0 !important;
  margin-top: 2rem;
  margin-bottom: 0.375rem;
  text-align: left !important;
`;

export const MetaInfo = styled(P)`
  color: ${theme.grayDarker};
  margin: 0;
  margin-bottom: 0.375rem;
  font-size: 1rem !important;
`;

export const Tag = styled.strong`
  display: inline-block;
  font-weight: bold;
  font-size: 0.9rem;
  border-radius: 2px;
  padding: 2px 5px 3px;
  color: ${theme.regolarWhite};
  background-color: ${theme.wavelopBlue};
  margin-right: 5px;
  margin-bottom: 5px;
`;

export const Summary = styled(H3)`
  color: ${theme.gray};
  font-style: italic;
  font-weight: 300;
  @media (min-width: ${theme.sizes.phone}px) {
    font-size: 1.375rem !important;
    margin-bottom: 2em;
  }
`;
