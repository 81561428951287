import styled, { css } from 'styled-components';
import { theme } from '../../theme/theme';

export const UL = styled.ul`
  & li {
    font-size: 1.125rem;
    padding: 8px 0;
  }

  @media (min-width: ${theme.sizes.phone}px) {
    & li {
      font-size: 1.25rem;
      padding: 0px;
    }
  }
`;
