import styled from 'styled-components';
import { theme } from '../../theme/theme';

export const Nav = styled.a`
  color: ${theme.deepBlue};
  text-decoration: none;
  margin-bottom: 0.75rem;
  font-weight: 300;
  font-size: 1.125rem;
  &:hover {
    text-decoration: underline;
  }

  @media (min-width: ${theme.sizes.phone}px) {
    font-size: 1.25rem;
  }
`;
