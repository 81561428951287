import React, { Component } from 'react';
import { IntlProvider, addLocaleData } from 'react-intl';
import { localeData } from './locales';

addLocaleData(localeData);

interface withIntlProps {}

interface withIntlState {
  language: string;
}

export default ComposedComponent => {
  class withIntl extends Component<withIntlProps, withIntlState> {
    constructor(props) {
      super(props);
      const { pageContext } = props;

      this.state = {
        language: pageContext.language
      };
    }

    render() {
      const { language } = this.state;
      const locale = language || 'en';
      const localeFiles = [
        'coverStrip',
        'footer',
        'form',
        'formStrip',
        'general',
        'header',
        'helmet',
        'languageSwitcher',
        'methodStrip',
        'notFoundPage',
        'servicesStrip',
        'newsStrip',
        'findUsStrip',
        'openWorksHomePageStrip'
      ];
      let messages = {};
      localeFiles.forEach(file => {
        messages = Object.assign(messages, require(`./${locale}/${file}.json`));
      });

      return (
        <IntlProvider locale={locale} messages={messages}>
          <ComposedComponent {...this.props} />
        </IntlProvider>
      );
    }
  }

  return withIntl;
};
