import styled, { css } from 'styled-components';
import { theme } from '../../theme/theme';

export const H1 = styled.h1`
  grid-row-end: span 1;
  grid-column-end: span 1;
  text-align: left;
  font-size: 2rem;
  font-weight: 500;
  padding: 0;
  width: 100%;

  @media (min-width: ${theme.sizes.phone}px) {
    grid-column-end: span 2;
    text-align: center;
    padding: 50px 0 0 0;
    font-size: 3rem;
  }

  @media (min-width: ${theme.sizes.tablet}px) {
    width: auto;
  }

  ${props =>
    props.visible === 'hidden' &&
    css`
      display: none;
    `};
`;
