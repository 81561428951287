import React from 'react';
import { SubmissionMessageComponent } from './submissionMessage-styled-component';

const SubmissionMessage = props => {
  return (
    <SubmissionMessageComponent {...props}>
      {props.message}
    </SubmissionMessageComponent>
  );
};
export default SubmissionMessage;
