import React, { Component } from 'react';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';

/** Import Components */
import {
  Input,
  Button,
  CheckBox,
  TextArea,
  SubmissionMessage
} from './components';

/** Style component */
import { Nav, Paragraph, ParagraphWithStyle } from './form-styled-component';

interface FormProps {
  intl;
}
interface ComponentState {
  model: any;
  interestsOption: any;
  selectOption: any;
}

class Form extends Component<FormProps, ComponentState> {
  _formModel: any;
  state: any;

  constructor(props) {
    super(props);
    const { intl } = this.props;

    this._formModel = {
      lastname: '',
      email: '',
      phone: '',
      interests: [],
      message: '',
      privacy_acceptance: false
    };

    this.state = {
      model: this._formModel,

      submissionState: {
        type: 'hide',
        message: ''
      },

      interestsOption: [
        {
          value: 'webSolution',
          label: intl.formatMessage({
            id: 'formWebSolution'
          })
        },
        {
          value: 'webApp',
          label: intl.formatMessage({
            id: 'formWebApp'
          })
        },
        {
          value: 'mobileApp',
          label: intl.formatMessage({
            id: 'formMobileApp'
          })
        },
        {
          value: 'chatBot',
          label: intl.formatMessage({
            id: 'formChatBot'
          })
        }
      ],

      selectOption: ['Select 1', 'Select 2']
    };

    this.handleFullName = this.handleFullName.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleCheckBox = this.handleCheckBox.bind(this);
    this.handleSingleCheckBox = this.handleSingleCheckBox.bind(this);
    this.handleTextArea = this.handleTextArea.bind(this);
  }

  handleInput(e) {
    e.persist();
    let value = e.target.value;
    let name = e.target.name;
    this.setState(
      prevState => ({
        model: {
          ...prevState.model,
          [name]: value
        }
      }),
      () => {}
    );
  }
  handleFullName(e) {
    e.persist();
    let value = e.target.value;
    this.setState(
      prevState => ({
        model: {
          ...prevState.model,
          lastname: value
        }
      }),
      () => {}
    );
  }
  handleTextArea(e) {
    let value = e.target.value;
    this.setState(
      prevState => ({
        model: {
          ...prevState.model,
          message: value
        }
      }),
      () => {}
    );
  }
  handleFormSubmit(e) {
    const { intl } = this.props;
    e.persist();
    e.preventDefault();
    this.setState(() => ({
      submissionState: {
        type: 'info',
        message: intl.formatMessage({
          id: 'formSubsInfoState'
        })
      }
    }));
    let userData = this.state.model;

    let fields = [];
    let keys = Object.keys(userData);
    fields = keys.map(k => {
      let value = userData[k];
      let obj = {
        name: k,
        value: ''
      };
      if (typeof value === 'boolean' || typeof value === 'number')
        obj.value = value.toString();
      else if (typeof value === 'string') obj.value = value;
      else if (typeof value === 'object') obj.value = value.join(';');

      return obj;
    });

    let data2send = {
      submittedAt: new Date().getTime().toString(),
      fields: fields,
      context: {
        pageUri: typeof window !== 'undefined' ? window.location.href : '#',
        pageName: typeof window !== 'undefined' ? window.document.title : ''
      },
      skipValidation: true
    };

    let data = JSON.stringify(data2send);

    fetch(
      'https://api.hsforms.com/submissions/v3/integration/submit/4881259/205e6bec-7aad-4966-8757-40a567b7a67a',
      {
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    )
      .then(response => {
        this.forceUpdate();
        response
          .json()
          .then(data => {
            this.setState(() => ({
              model: this._formModel,
              submissionState: {
                type: 'success',
                message: intl.formatMessage({
                  id: 'formSubsSuccessState'
                })
              }
            }));
          })
          .catch(error => {
            this.setState(() => ({
              submissionState: {
                type: 'error',
                message: intl.formatMessage({
                  id: 'formSubsErrorState'
                })
              }
            }));
          });
      })
      .catch(error => {
        this.setState(() => ({
          submissionState: {
            type: 'error',
            message: intl.formatMessage({
              id: 'formSubsErrorState'
            })
          }
        }));
      });
  }

  handleCheckBox(e) {
    const newSelection = e.target.value;
    let newSelectionArray;

    if (this.state.model.interests.indexOf(newSelection) > -1) {
      newSelectionArray = this.state.model.interests.filter(
        s => s !== newSelection
      );
    } else {
      newSelectionArray = [...this.state.model.interests, newSelection];
    }
    this.setState(prevState => ({
      model: { ...prevState.model, interests: newSelectionArray }
    }));
  }

  handleSingleCheckBox(e) {
    const target = e.target.checked;
    const name = e.target.name;
    this.setState(
      prevState => ({
        model: {
          ...prevState.model,
          [name]: target
        }
      }),
      () => {}
    );
  }

  render() {
    const { intl } = this.props;

    let privacyPage = `/${intl.locale}/privacy-cookies/`;

    return (
      <form className="container-fluid" onSubmit={this.handleFormSubmit}>
        <Input
          inputtype={'text'}
          title={intl.formatMessage({ id: 'formYourName' })}
          name={'lastname'}
          value={this.state.model.lastname}
          placeholder={intl.formatMessage({ id: 'formNameExample' })}
          handlechange={this.handleInput}
          required={true}
        />{' '}
        <Input
          inputtype={'email'}
          title={intl.formatMessage({ id: 'formYourEmail' })}
          name={'email'}
          value={this.state.model.email}
          placeholder={intl.formatMessage({ id: 'formEmailExample' })}
          handlechange={this.handleInput}
          required={true}
        />{' '}
        <Input
          inputtype={'text'}
          title={intl.formatMessage({ id: 'formYourPhone' })}
          name={'phone'}
          value={this.state.model.phone}
          placeholder={intl.formatMessage({
            id: 'formPhoneExample'
          })}
          handlechange={this.handleInput}
        />{' '}
        <CheckBox
          title={intl.formatMessage({ id: 'formYourInterests' })}
          name={'interests'}
          options={this.state.interestsOption}
          selectedOptions={this.state.model.interests}
          handlechange={this.handleCheckBox}
        />{' '}
        <TextArea
          title={intl.formatMessage({ id: 'formLeaveAMessage' })}
          rows={10}
          value={this.state.model.message}
          name={'message'}
          handlechange={this.handleTextArea}
          placeholder={intl.formatMessage({
            id: 'formMessageExample'
          })}
        />
        <Paragraph>
          {intl.formatMessage({ id: 'formAcceptancePrivacy' })}
          <Nav
            href={privacyPage}
            target="_BLANK"
            title={intl.formatMessage({ id: 'formLinkTitle' })}
          >
            {intl.formatMessage({ id: 'formLinkTitle' })}
          </Nav>
        </Paragraph>
        <ParagraphWithStyle>
          <Button
            onSubmit={this.handleFormSubmit}
            type={'submit'}
            value={intl.formatMessage({ id: 'formSend' })}
          />{' '}
          <FormattedHTMLMessage id="formSendEmailTo" />
        </ParagraphWithStyle>
        <SubmissionMessage
          type={this.state.submissionState.type}
          message={this.state.submissionState.message}
        />
      </form>
    );
  }
}

export default injectIntl(Form);
