import styled from 'styled-components';
import { theme } from '../../theme/theme';

export const TitleH2 = styled.h3`
  grid-row-end: span 1;
  grid-column-end: span 2;
  text-align: center;
  font-size: 2rem;
  font-weight: 500;
  padding: 0;
  margin-top: 0px;
  @media (max-width: ${theme.sizes.phone}px) {
    grid-column-end: span 1;
    text-align: left;
  }
  &.mainTitle {
    font-size: 3.5rem;
  }
`;

export const Description = styled.p`
  font-weight: 300;
  font-size: 1.5rem;
  grid-row-end: span 1;
  grid-column-end: span 2;
  text-align: center;
  white-space: break-spaces;
  margin: 0 auto;
  @media (max-width: ${theme.sizes.phone}px) {
    grid-column-end: span 1;
    width: 100%;
    text-align: left;
    margin: 0;
  }
  &.endDescription {
    background-color: ${theme.lightWhite};
    padding-bottom: 1rem;
  }
`;
