import React from 'react';

import { Container, Title, Summary, Date } from './eventtile-styled-component';

const EventTile = props => {
  const { data, column, row } = props;

  return (
    <Container column={column} row={row}>
      <Date>{data.meta.shortDate}</Date>
      <Title>{data.meta.title}</Title>
      <Summary>{data.meta.summary}</Summary>
    </Container>
  );
};

export default EventTile;
